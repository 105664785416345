import React from "react"
import Footer from "../../common-components/Footer/Footer"
import Header from "../../common-components/Header/Header"
import SendRequest from "../../common-components/SectionComponents/SendRequest"
import ProspectContext from "../../common-components/SectionComponents/ProspectContext"
import MenuLink from "../../common-components/Navigation/MenuLink"
import MenuPage from "../menu-page/MenuPage"
import { scroll } from "../../utils/locomotiveScroll"

function ProspectPage() {
  const [menuOpen, setMenuOpen] = React.useState(false)
  const [isMobile, setisMobile] = React.useState(null)

  const changeMobile = () => {
    window.matchMedia("(max-width: 37.5em)").matches
      ? setisMobile(true)
      : setisMobile(false)
  }

  React.useEffect(() => {
    window.scroll(0, 0)
    scroll()
    changeMobile()
    window.addEventListener("resize", changeMobile)
    return () => window.removeEventListener("resize", changeMobile)
  }, [])

  return (
    <>
      {isMobile ? (
        <>
          <Header />
          {!menuOpen && (
            <>
              <ProspectContext />
              <SendRequest />
              <Footer />
            </>
          )}
          <MenuPage setMenuOpen={setMenuOpen} menuOpen={menuOpen} />
          <MenuLink setMenuOpen={setMenuOpen} menuOpen={menuOpen} />
        </>
      ) : (
        <>
          <Header />
          <ProspectContext />
          <SendRequest />
          <Footer />
          <MenuPage setMenuOpen={setMenuOpen} menuOpen={menuOpen} />
          <MenuLink setMenuOpen={setMenuOpen} menuOpen={menuOpen} />
        </>
      )}
    </>
  )
}

export default ProspectPage
