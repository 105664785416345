import { Link } from "react-router-dom"
import styled from "styled-components"

export const LayoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: var(--color-comp);
`

export const BannerWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: flex-start;
  background-color: var(--color-blk);
`

export const SectionWrapper = styled.div`
  color: white;
  display: flex;
  width: 100%;
  height: 120vh;
  align-items: center;
  justify-content: center;
  background-color: var(--color-comp);
  @media ${({ theme }) => theme.mediaQueries.mobile}{
  }
  @media ${({ theme }) => theme.mediaQueries.ipad_pro}{
    height: 80vh;
    margin-top: 10%
  }
  @media ${({ theme }) => theme.mediaQueries.tablet_landscap}{
    height: 80vh;
  }
`

export const SectionWrapperImg = styled.div`
  color: white;
  display: flex;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
  opacity: 0.5;
  min-height: 100%;
  background-repeat: no-repeat;
  /* background-attachment: fixed; */
  background-position: center;
  background-size: cover;
`

export const HorizontalScrollableSectionWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(75, calc(100% - 36%));
  padding-left: 35rem;
  overflow-x: auto;
  overflow-y: hidden;
  column-gap: 18%;
  padding-left: 9%;
  scroll-behavior: smooth;
  ::-webkit-scrollbar {
    width: 0px;
    background: transparent;
    }
    @media ${({ theme }) => theme.mediaQueries.ipad_pro}{
      column-gap: 35%;
      padding-left: 13%;
      grid-template-columns: repeat(75,calc(125% - 40%));
    }

    @media ${({ theme }) => theme.mediaQueries.tablet}{
      grid-template-columns: repeat(75,calc(118% - 34%));
      column-gap: 30%;
    }
`

export const InsideSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: flex-start;
  padding: 6% 18%;
  @media ${({ theme }) => theme.mediaQueries.mobile}{
    48% 0% 0% 8%;
    
  }
  @media ${({ theme }) => theme.mediaQueries.tablet}{
    height: 60%;
  }
`

export const BannerOverlayWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
`

export const BannerOverlayTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 60vh;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0% 12%;
`
export const ActionFirst = styled.div`
  display: flex;
  width: 100%;
  height: 30vh;
  align-items: center;
  justify-content: space-between;
  padding: 0% 12%;
`

export const ActionSecond = styled.div`
  display: flex;
  width: 100%;
  height: 15vh;
  align-items: center;
  justify-content: center;
  padding: 0% 12%;
`

export const PrevNextWrapper = styled.div`
  display: flex;
  width: auto;
  align-items: center;
  justify-content: center;
`

export const SubActWrapper = styled.div`
  display: flex;
  width: auto;
  align-items: center;
  justify-content: center;
`

export const MenuStyledLinkWrapper = styled.div`
  display: flex;
  width: auto;
  align-items: center;
  justify-content: center;

  position: absolute;
  right: 4%;
  top: 42%;
  transform: rotate(-90deg);
  cursor: pointer;
  z-index: 10;
  mix-blend-mode: difference;
  transition: all var(--speed);

  @media ${({ theme }) => theme.mediaQueries.mobile_landscape} {
    position: absolute;
    right: 7%;
    top: 16%;
    transform: none;
    cursor: pointer;
    z-index: 10;
    mix-blend-mode: difference;
    transition: all var(--speed);
  }
`

export const SayWrapper = styled.div`
  display: flex;
  width: 100%;
  height: auto;
  align-items: center;
  justify-content: flex-start;
`

export const SayingWrapper = styled.div`
  display: flex;
  width: 100%;
  height: auto;
  align-items: center;
  justify-content: flex-start;
  margin: 6rem 0rem;
  width: 100%;
`
export const DetailedSayingWrapper = styled.div`
  display: flex;
  width: 100%;
  height: auto;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 2rem;
  width: 60%;
  marginTop: 5rem;
  @media ${({ theme }) => theme.mediaQueries.tablet}{
    width: 75%;
  }
  @media ${({ theme }) => theme.mediaQueries.tablet_landscape}{
    width: 80%;
  }
  @media ${({ theme }) => theme.mediaQueries.ipad_pro}{
    width: 80%;

  }
`

export const ContentWrapper = styled.div`
  width: 100%;
  height: auto;
  display: block;
`

export const ContentWrapper2 = styled(Link)`
  width: 100%;
  height: 68vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: center;
  text-decoration: none;
`

export const ContentWrapperLink = styled(Link)`
  width: 100%;
  height: auto;
  display: block;
  text-decoration: none;
`

export const CardImageWrapper = styled.div`
  width: auto;
  height: 100%;
  min-width: fit-content;
`

export const CardTextWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  height: auto;
  justify-content: center;
  align-items: flex-start;
  margin: 6rem 0rem 8rem 0rem;
`

export const CardHeaderWrapper = styled.div`
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  display: flex;
  margin-bottom: 3rem;
`

export const CardSubtextWrapper = styled.div`
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  display: flex;
`

export const ServicesWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: inherit;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 6% 12%;

  @media ${({ theme }) => theme.mediaQueries.ipad_pro}{

  }
`
