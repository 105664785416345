import styled from "styled-components"

export const BannerOverlaySubtext = styled.p`
  color: white;
  font-family: roboto;
  font-size: 0.8rem;
  text-transform: uppercase;
  letter-spacing: 0.3rem;
  font-weight: 600;
`

export const BannerOverlaySubtext1 = styled.p`
  color: white;
  font-family: roboto;
  font-size: 0.8rem;
  text-transform: uppercase;
  letter-spacing: 0.3rem;
  font-weight: 600;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    font-size: 0.7rem;
    opacity: 0.8;
    letter-spacing: 0.28rem;
    font-weight: 400;
    line-height: 1.5rem;
  }
`

export const BannerOverlayText = styled.h1`
  color: white;
  font-family: graphik;
  font-size: 3.5rem;
  letter-spacing: 0.1rem;
  font-weight: 700;
  width: 40%;
  margin-top: 4rem;
`

export const BannerOverlayText3 = styled.h1`
  color: white;
  font-family: graphik;
  font-size: 3.5rem;
  letter-spacing: 0.1rem;
  font-weight: 700;
  width: 40%;
  margin-top: 4rem;

  width: 100%;
  margin-top: 6rem;
  margin-bottom: 8rem;
  font-weight: 600;
  font-family: roboto;
  font-size: 2rem;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    width: 100%;
    margin-top: 2rem;
    margin-bottom: 3rem;
    /* font-weight: 600; */
    /* font-family: roboto; */
    /* font-size: 2rem; */
    text-decoration: none;
    color: white;
    font-family: roboto;
    font-size: 1.2rem;
    text-transform: capitalize;
    -webkit-letter-spacing: 0.05rem;
    -moz-letter-spacing: 0.05rem;
    -ms-letter-spacing: 0.05rem;
    letter-spacing: 0.05rem;
    font-weight: 600;
  }
`

export const BannerOverlayText2 = styled.h1`
  color: white;
  font-family: roboto;
  font-size: 2rem;
  letter-spacing: 0.1rem;
  font-weight: 300;
  width: 100%;
  margin-top: 3rem;
  margin-bottom: 10rem;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    font-weight: 300;
    font-family: roboto;
    /* font-size: 3.2rem; */
    font-size: 1.4rem;
    /* font-weight: 800; */
    width: 100%;
    line-height: 2.5rem;
    margin-top: 0rem;
    margin-bottom: 3rem;
  }
`

export const BannerOverlayText1 = styled.h1`
  color: white;
  font-family: roboto;
  font-size: 3.2rem;
  letter-spacing: 0.1rem;
  font-weight: 300;
  width: 100%;
  margin-top: 5rem;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    font-weight: 300;
    font-family: roboto;
    /* font-size: 3.2rem; */
    font-size: 2rem;
    /* font-weight: 800; */
    width: 100%;
    line-height: 2.5rem;
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
`

export const ActionLinks = styled.p`
  color: white;
  font-size: 0.8rem;
  font-family: "Roboto";
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0.1rem;
`

export const ActionLinks1 = styled.p`
  color: white;
  font-size: 0.8rem;
  font-family: "Roboto";
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0.1rem;

  margin-left: 0.5rem;
  letter-spacing: 0.12rem;
  font-weight: 700;
  background: var(--color-pri);
  font-size: 0.8rem;
  color: var(--color-comp);
  padding: 1.2rem 1.6rem;
  cursor: pointer;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    letter-spacing: 0.12rem;
    font-weight: 700;
    background: var(--color-pri);
    font-size: 0.8rem;
    color: var(--color-comp);
    padding: 1rem 1.4rem;
    cursor: pointer;
    margin-top: 15%;
  }
`
export const ActionLinks2 = styled.p`
  color: white;
  font-size: 0.8rem;
  font-family: "Roboto";
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0.1rem;

  margin-top: 10%;
  letter-spacing: 0.12rem;
  font-weight: 700;
  background: var(--color-pri);
  font-size: 0.8rem;
  color: var(--color-comp);
  padding: 1.2rem 1.6rem;
  cursor: pointer;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    letter-spacing: 0.12rem;
    font-weight: 700;
    background: var(--color-pri);
    font-size: 0.8rem;
    color: var(--color-comp);
    padding: 1rem 1.4rem;
    cursor: pointer;
    margin-top: 15%;
  }
`

export const InputText = styled.input`
  width: 100%;
  font-weight: 300;
  font-family: roboto;
  font-size: 1.8rem;
  border: none;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  padding: 0% 0.2rem;
  padding-bottom: 1.4rem;
  background: transparent;
  color: var(--color-wht);
  letter-spacing: 0.12rem;
  transition: all var(--speed);

  ::placeholder {
    color: rgba(255, 255, 255, 0.5);
    font-weight: 300;
    font-size: 1.8rem;
    letter-spacing: 0.05rem;
  }

  &:hover {
    border-bottom: 1px solid rgba(255, 255, 255, 0.7);
  }

  &:focus {
    border-bottom: 1px solid rgba(255, 255, 255, 0.7);
  }

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    margin-top: 0rem;
    margin-bottom: 3rem;
    font-size: 1rem;
    letter-spacing: 0.05rem;
    font-weight: 300;

    ::placeholder {
      color: rgba(255, 255, 255, 0.5);
      font-weight: 300;
      font-size: 1rem;
      letter-spacing: 0.05rem;
    }
  }
`
