import styled from "styled-components"

export const BannerOverlaySubtext = styled.p`
  color: white;
  font-family: roboto;
  font-size: 0.8rem;
  text-transform: uppercase;
  letter-spacing: 0.3rem;
  font-weight: 600;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    z-index: 1;
    font-size: 0.7rem;
    letter-spacing: 0.2rem;
    opacity: 0.8;
  }

  @media ${({ theme }) => theme.mediaQueries.mobile_landscape} {
    z-index: 1;
    font-size: 0.7rem;
    letter-spacing: 0.2rem;
    opacity: 0.8;
  }
`

export const BannerOverlayText = styled.h1`
  color: white;
  font-family: graphik;
  font-size: 3.2rem;
  letter-spacing: 0.1rem;
  font-weight: 700;
  width: 40%;
  margin-top: 4rem;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    font-weight: 800;
    font-size: 2rem;
    width: 100%;
    line-height: 3.5rem;
    margin-top: 2rem;
  }

  @media ${({ theme }) => theme.mediaQueries.mobile_landscape} {
    font-weight: 800;
    font-size: 1.8rem;
    width: 68%;
    line-height: 2.5rem;
    margin: 0%;
    margin-top: 1rem;
  }
  @media ${({ theme }) => theme.mediaQueries.tablet}{
    width: 50%;
  }
  @media ${({ theme }) => theme.mediaQueries.tablet_landscape}{
    width: 50%;
  }
`

export const ActionLinks = styled.p`
  color: white;
  font-size: 0.8rem;
  font-family: "Roboto";
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0.1rem;

  @media ${({ theme }) => theme.mediaQueries.mobile_landscape} {
    color: white;
    font-family: "Roboto";
    text-transform: uppercase;
    -webkit-letter-spacing: 0.1rem;
    -moz-letter-spacing: 0.1rem;
    -ms-letter-spacing: 0.1rem;
    letter-spacing: 0.1rem;
    margin-right: 0rem;
    font-size: 0.7rem;
    font-weight: 400;
  }
`

export const ActionLinks2 = styled.p`
  color: white;
  font-family: "Roboto";
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  margin-right: ${(props) => (props.left ? "7rem" : "0rem")};
  font-size: 0.7rem;
  font-weight: 400;

  @media ${({ theme }) => theme.mediaQueries.mobile_landscape} {
    color: white;
    font-family: "Roboto";
    text-transform: uppercase;
    -webkit-letter-spacing: 0.1rem;
    -moz-letter-spacing: 0.1rem;
    -ms-letter-spacing: 0.1rem;
    letter-spacing: 0.1rem;
    margin-right: ${(props) => (props.left ? "7rem" : "2rem")};
    font-size: 0.6rem;
    font-weight: 400;
  }
`
