import React from "react"
import {
  // DetailedSayingWrapper,
  // InsideSectionWrapper,
  PrevNextWrapper,
  // SectionWrapper,
  SectionWrapper1,
  InsideSectionWrapper1,
  // ServicesWrapper,
  SubActWrapper,
  SectionWrapper3,
  SectionWrapper2,
  InsideSectionWrapper2,
  DetailedSayingWrapper1,
  SubActWrapper1,
  SectionWrapper4,
  ServicesWrapper1,
  ServicesWrapper2,
  DetailedSayingWrapper2,
  SectionWrapper5,
  ServicesWrapper3,
  InsideSectionWrapper3,
  SectionWrapper6,
  TitleBg,
} from "../../building-blocks/atoms/containers/AboutUsContextContainer"
import { BackImg } from "../../building-blocks/atoms/images/AboutUsContextImage"
import {
  // BannerOverlayText,
  // BannerOverlaySubtext,
  ActionLinks,
  BannerOverlaySubtext1,
  BannerOverlayText1,
  ActionLinks1,
  BannerOverlayText2,
  ActionLinks2,
  BannerOverlayText3,
  BannerOverlayText4,
  ActionLinks3,
} from "../../building-blocks/atoms/texts/AboutUsContextText"
import WhatWeDoImg from "../../assets/images/whatwedo2.jpg"
import WhatWeDoImg2 from "../../assets/images/whatwedo3.jpg"
import WhatWeDoImg3 from "../../assets/images/whatwedo4.jpg"
import { scroll } from "../../utils/locomotiveScroll"
import styled from "styled-components"

const TextDo = [
  [
    "Being different > being better.",
    "Don’t predict the future, create it.",
    "Purpose begets progress.",
    "Adaptation is the new normal.",
    "Relationship matters.",
    "We are one idea away from changing the world.",
    "Get in Touch",
  ],
  [
    "Use creativity as a commodity.",
    "Design by committee.",
    "Leave clients hanging.",
    "Play it safe.",
    "Point fingers and make excuses.",
    "Beat around the bush. ",
  ],
]

function AboutUsContext() {
  React.useEffect(() => {
    scroll()
  }, [])

  const [isMobile, setisMobile] = React.useState(null)

  const changeMobile = () => {
    window.matchMedia("(max-width: 37.5em)").matches
      ? setisMobile(true)
      : setisMobile(false)
  }

  React.useEffect(() => {
    changeMobile()
    window.addEventListener("resize", changeMobile)
    return () => window.removeEventListener("resize", changeMobile)
  }, [])

  return (
    <>
      <SectionWrapper1 data-scroll-section>
        <InsideSectionWrapper1>
          <BannerOverlaySubtext1>Hello we're Honeysys.</BannerOverlaySubtext1>
          <TitleBg />

          <>
            <BannerOverlayText1 data-scroll data-scroll-speed="-2">
              Potential Company
              <br />
              With Prospects And Unique Platforms In Portfolio.
            </BannerOverlayText1>
          </>
        </InsideSectionWrapper1>
      </SectionWrapper1>

      <SectionWrapper2 data-scroll-section>
        <BackImg
          src={WhatWeDoImg}
          style={{ height: "130vh" }}
          alt=""
          data-scroll
          data-scroll-speed="-5"
        />
      </SectionWrapper2>

      <SectionWrapper3 data-scroll-section>
        <InsideSectionWrapper2>
          <DetailedSayingWrapper1>
            <ActionLinks1 data-scroll data-scroll-speed="1">
              We are one of the global leading information technology (IT)
              consulting, product development and staffing solution firm, where
              bold thinking, inspired people and aimed high at providing
              efficient information technology-driven business solutions and
              services that meet the objectives of our clients. Honeysys helps
              transform the world’s most important business into robust, agile
              organisations that anticipate the unpredictable, adapt rapidly to
              disruption and outcompete their opposition.
            </ActionLinks1>
          </DetailedSayingWrapper1>

          <SubActWrapper1>
            <ActionLinks>what we do</ActionLinks>
          </SubActWrapper1>
        </InsideSectionWrapper2>
      </SectionWrapper3>

      <SectionWrapper4 data-scroll-section>
        {isMobile ? (
          <ServicesWrapper1>
            <img
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
              src={WhatWeDoImg2}
              alt=""
            />
            <SubActWrapper style={{ position: "absolute", width: "100%" }}>
              <PrevNextWrapper>
                <ActionLinks style={{ marginRight: "5rem" }}>prev</ActionLinks>
                <ActionLinks>next</ActionLinks>
              </PrevNextWrapper>
            </SubActWrapper>
          </ServicesWrapper1>
        ) : (
          <></>
        )}

        <ServicesWrapper2>
          <>
            <BannerOverlayText2 data-scroll data-scroll-speed="2">
              Our Mission
            </BannerOverlayText2>
          </>

          <DetailedSayingWrapper2>
            <ActionLinks2 data-scroll data-scroll-speed="1">
              To redefine the IT industry & to focus on delivering client
              results instead of reports. We have continued to be innovators for
              our business industry, with an entrepreneurial culture that is
              never satisfied with the status quo, ourselves or for our clients.
            </ActionLinks2>
          </DetailedSayingWrapper2>

          {isMobile ? (
            <></>
          ) : (
            <SubActWrapper style={{ marginLeft: "25%" }}>
              <PrevNextWrapper>
                <ActionLinks style={{ marginRight: "5rem" }}>prev</ActionLinks>
                <ActionLinks>next</ActionLinks>
              </PrevNextWrapper>
            </SubActWrapper>
          )}
        </ServicesWrapper2>

        {isMobile ? (
          <></>
        ) : (
          <Image
            style={{  }}
            src={WhatWeDoImg2}
            alt=""
          />
        )}
      </SectionWrapper4>

      <SectionWrapper6 data-scroll-section>
        <InsideSectionWrapper3>
          <BannerOverlaySubtext1>Our belief</BannerOverlaySubtext1>
          <>
            <BannerOverlayText3 data-scroll data-scroll-speed="1">
              What we believe
            </BannerOverlayText3>
            <BannerOverlayText4
              style={{
                color: "var(--color-pri)",
              }}
              data-scroll
              data-scroll-speed="3"
            >
              <s>What we don't!</s>
            </BannerOverlayText4>
          </>
        </InsideSectionWrapper3>

        <ServicesWrapper3 data-scroll data-scroll-speed="1">
          {TextDo[0].map((item) => {
            return <ActionLinks3>&#8594; {item}</ActionLinks3>
          })}
        </ServicesWrapper3>
      </SectionWrapper6>

      <SectionWrapper2 data-scroll-section>
        <BackImg
          src={WhatWeDoImg3}
          style={{ height: "150vh" }}
          alt=""
          data-scroll
          data-scroll-speed="-5"
        />
      </SectionWrapper2>

      <SectionWrapper5 data-scroll-section>
        <InsideSectionWrapper2>
          <DetailedSayingWrapper1 data-scroll data-scroll-speed="1">
            <ActionLinks1>
              Our Team Firmly Believes To Be A Well-Connected And Cohesive
              Community. We Push Each Other To Get Better; We Could Never Be As
              Good Alone As We Are Together. We Are A Team Of Talented
              Individuals, Putting Best Results To The Table With The Teamwork.
            </ActionLinks1>
          </DetailedSayingWrapper1>

          <SubActWrapper1>
            <ActionLinks>Our Team</ActionLinks>
          </SubActWrapper1>
        </InsideSectionWrapper2>
      </SectionWrapper5>
    </>
  )
}

export default AboutUsContext


const Image = styled.img`
width: 60%;
objectFit: cover;
@media ${({ theme }) => theme.mediaQueries.tablet}{
  margin-left: -280px;
  margin-top: -600px;
  height: 60vh;
}
@media ${({ theme }) => theme.mediaQueries.ipad_pro}{
  height: 60vh;
}

`