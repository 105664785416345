import React ,{useEffect} from "react"
import Helmet from "react-helmet"
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import Layout from "./common-components/Layout/Layout"
import "./App.css"
//import lax from "lax.js"

// Page Components
import HomePage from "./components/home-page/HomePage"
import AboutUsPage from "./components/about-us-page/AboutUsPage"
import WhatWeDoPage from "./components/what-we-do-page/WhatWeDoPage"
import ProspectPage from "./components/prospect-page/ProspectPage"
import ContactUsPage from "./components/contact-us-page/ContactUsPage"
import PortfolioPage from "./components/portfolio-page/PortfolioPage"
import OurTeamPage from "./components/our-team-page/OurTeamPage"
import DigitizationPage from "./components/digitization-page/DigitizationPage"
import OfflineBrandSolutionsPage from "./components/offline-brand-solutions-page/OfflineBrandSolutionsPage"
import MarketingAndPromotionsPage from "./components/marketing-and-promotions-page/MarketingAndPromotionsPage"
import SalesPage from "./components/sales-page/SalesPage"
import LogisticsPage from "./components/logistics-page/LogisticsPage"
import SecurityPage from "./components/security-page/SecurityPage"
import TermsConditionsPage from "./components/terms-conditions-page/TermsConditionsPage"
import PrivacyPolicyPage from "./components/privacy-policy-page/PrivacyPolicyPage"
import ConsultingServicesPage from "./components/consulting-services-page/ConsultingServicesPage"
import WorkWithUsPage from "./components/work-with-us-page/WorkWithUsPage"
import PotentialPage from "./components/potential-page/PotentialPage"
import CartzsPage from "./components/cartzs-page/CartzsPage"
import DashboardPage from "./components/dashboard-page/DashboardPage"
import CosmosPage from "./components/cosmos-page/CosmosPage"
import KiranaPage from "./components/kirana-king-page/KiranakingPage"
import emaratPage from "./components/emaratPage/emaratPage"
import PlatformPage from "./components/platform-page/PlatformPage"
import KrazyfastPage from "./components/krazyfast-page/KrazyfastPage"
import GoshoppiPage from "./components/goshoppi-page/GoshoppiPage"
import mshoppiPage from "./components/mshoppi-page/MshoppiPage"

const App = () => {


  
  // var pathName = window.location.pathname
  //   .slice(1)
  //   .replace(/-/g, " ")
  //   .toLowerCase()
  //   .replace(/(\b[a-z](?!\s))/g, function (item) {
  //     return item.toUpperCase()
  //   })

  // React.useEffect(() => {
  //   lax.init()
  //   lax.addDriver("scrollY", function () {
  //     return window.scrollY
  //   })
  //   lax.addElements(
  //     ".lax_opacity_header",
  //     {
  //       scrollY: {
  //         opacity: [
  //           [0, 130],
  //           [1, 0],
  //         ],
  //         transform: [[0], [0], [0]],
  //       },
  //     },
  //     []
  //   )
  //   lax.addElements(
  //     ".lax_opacity_scroll_down",
  //     {
  //       scrollY: {
  //         opacity: [
  //           [0, 200],
  //           [1, 0],
  //         ],
  //         transform: [[0], [0], [0]],
  //       },
  //     },
  //     []
  //   )
  // }, [])
  useEffect(() => {
    const cursor = document.querySelector(".cursor")
    document.addEventListener("mousemove", e => {
      cursor.setAttribute("style", "top: " + (e.pageY - 1 ) + "px; left: " + (e.pageX - 1) + "px;")
      let r = Math.floor(Math.random() * 210);
      let g = Math.floor(Math.random() * 250);
      let b = Math.floor(Math.random() * 230);
      cursor.style.backgroundColor = `rgb(${r},${g},${b})`
    })
  }, [])

  return (
    <>
     <div className="cursor"></div>
      <Helmet>
        {/* <title>{pathName ? pathName + " - Honeysys" : "Honeysys"}</title> */}
        <title>Honeysys</title>
      </Helmet>

      <Router>
        <Layout>
          <Switch>
            <Route exact path="/" component={HomePage} />
            <Route exact path="/about-us" component={AboutUsPage} />
            <Route exact path="/what-we-do" component={WhatWeDoPage} />
            <Route exact path="/prospect" component={ProspectPage} />
            <Route exact path="/contact-us" component={ContactUsPage} />
            <Route exact path="/portfolio" component={PortfolioPage} />
            <Route exact path="/our-team" component={OurTeamPage} />
            <Route exact path="/digitization" component={DigitizationPage} />
            <Route
              exact
              path="/offline-brand-solutions"
              component={OfflineBrandSolutionsPage}
            />
            <Route
              exact
              path="/marketing-and-promotions"
              component={MarketingAndPromotionsPage}
            />
            <Route exact path="/sales" component={SalesPage} />
            <Route exact path="/logistics" component={LogisticsPage} />
            <Route exact path="/security" component={SecurityPage} />
            <Route exact path="/terms-of-use" component={TermsConditionsPage} />
            <Route exact path="/privacy-policy" component={PrivacyPolicyPage} />
            <Route
              exact
              path="/consulting-services"
              component={ConsultingServicesPage}
            />
            <Route exact path="/work-with-us" component={WorkWithUsPage} />
            <Route exact path="/potential" component={PotentialPage} />
            <Route exact path="/cartzs" component={CartzsPage} />
            <Route exact path="/dashboard" component={DashboardPage} />
            <Route exact path="/cosmos" component={CosmosPage} />
            <Route exact path="/kiranaking" component={KiranaPage} />
            <Route exact path="/emarat" component={emaratPage} />
            <Route exact path="/platform" component={PlatformPage} />
            <Route exact path="/krazy-fast" component={KrazyfastPage} />
            <Route exact path="/goshoppi" component={GoshoppiPage} />
            <Route exact path="/mshopi" component={mshoppiPage} />
          </Switch>
        </Layout>
      </Router>
    </>
  )
}

export default App


