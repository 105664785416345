import React from "react"
import styled from "styled-components"
import { Link } from "react-router-dom"
import { v4 as uuidv4 } from "uuid"
import {
  ContentWrapper2,
  HorizontalScrollableSectionWrapper,
  SectionWrapper,
} from "../../building-blocks/atoms/containers/containers"
import { CardImg } from "../../building-blocks/atoms/images/images"
import {
  BannerOverlayText,
  BannerOverlaySubtext,
  ActionLinks,
  CardHeaderText2,
} from "../../building-blocks/atoms/texts/texts"
import portfolio1 from "../../assets/images/portfolio1.jpg"
import portfolio2 from "../../assets/images/portfolio2.jpg"
import portfolio3 from "../../assets/images/portfolio3.jpg"
import portfolio4 from "../../assets/images/portfolio4.png"
import portfolio5 from "../../assets/images/portfolio5.png"
import platform1 from "../../assets/images/platform1.jpg"
import malls from "../../assets/images/malls.jpeg"
import { scroll } from "../../utils/locomotiveScroll"
import goshoppi from "../../assets/images/goshoppi.jpeg"

//console.log(uuidv4())

const LIST = [
  {
    id: uuidv4(),
    step: "1/5",
    img: goshoppi,
    location: "India, Dubai 2021",
    product: "Goshoppi",
    sector: "Industry - Retailer To E-tailer",
    url: "/goshoppi",
  },
  {
    id: uuidv4(),
    step: "2/5",
    img: portfolio1,
    location: "India, Dubai 2021",
    product: "Cartzs",
    sector: "Industry - Ecommerce marketplace",
    url: "/cartzs",
  },
 
  {
    id: uuidv4(),
    step: "3/5",
    img: platform1,
    location: "India, Dubai 2021",
    product: "Krazy Fast",
    sector: "Industry - Logistics",
    url: "/krazy-fast",
  },
  {
    id: uuidv4(),
    step: "4/5",
    img: malls,
    location: "India, Dubai 2021",
    product: "Mshopi",
    sector: "Industry - Online-Malls",
    url: "/mshopi",
  },
  
  {
    id: uuidv4(),
    step: "5/5",
    img: portfolio2,
    location: "Dubai, 2021",
    product: "Dashboard",
    sector: "Industry - Ecommerce",
    url: "/dashboard",
  },
  
  
]

const LIST2 = [
  {
    id: uuidv4(),
    step: "1/3",
    img: portfolio3,
    location: "Dubai, 2020",
    product: "Cosmos",
    sector: "Industry - Sports",
    url: "/cosmos",
  },
  {
    id: uuidv4(),
    step: "2/3",
    img: portfolio4,
    location: "UAE & India, 2020",
    product: "Kirana King",
    sector: "Industry - Retailer",
    url: "/kiranaking",
  },
  {
    id: uuidv4(),
    step: "3/3",
    img: portfolio5,
    location: "UAE, 2020",
    product: "Emarat",
    sector: "Industry - Fuel & Gas",
    url: "/emarat",
  },
]

// const Wrapper = styled.div`
//   display: grid;
//   column-gap: 12%;
//   grid-template-columns: 1fr 1fr;

//   div:nth-of-type(even) {
//     margin-top: 15rem;
//     margin-bottom: 9rem;
//     float: right;
//   }
// `

function PortfolioContext() {
  const [toggle1, setToggle1] = React.useState(true)
  const [toggle2, setToggle2] = React.useState(false)
  const [isMobile, setisMobile] = React.useState(null)

  const changeMobile = () => {
    window.matchMedia("(max-width: 37.5em)").matches
      ? setisMobile(true)
      : setisMobile(false)
  }

  React.useEffect(() => {
    window.scroll(0, 0)
    scroll()
    changeMobile()
    window.addEventListener("resize", changeMobile)
    return () => window.removeEventListener("resize", changeMobile)
  }, [])

  React.useEffect(() => {
    scroll()
    const currentToggled = localStorage.getItem('toggle');
    if(currentToggled === 'toggle_2') {
      setToggle2(true);
      setToggle1(false);
    } else {
      setToggle2(false);
      setToggle1(true);
    }
  }, [])

  const handleToggle1 = () => {
    setToggle1(true)
    setToggle2(false)
    localStorage.setItem('toggle', 'toggle_1')
  }

  const handleToggle2 = () => {
    setToggle1(false)
    setToggle2(true)
    localStorage.setItem('toggle', 'toggle_2')
  }

  const BannerOverlayText0 = styled.h1`
    cursor: pointer;
    width: auto;
    height: auto;
    font-size: ${toggle1 === true ? "1.7rem" : "1rem"};
    font-weight: ${toggle1 === true ? "600" : "400"};
    color: ${toggle1 === true ? "var(--color-pri)" : "var(--color-wht)"};
    opacity: ${toggle1 === true ? "1" : "0.5"};
    font-family: graphik;
  `

  const BannerOverlayText01 = styled.h1`
    cursor: pointer;
    width: auto;
    height: auto;
    font-size: ${toggle2 === true ? "1.5rem" : "1rem"};
    font-weight: ${toggle2 === true ? "600" : "400"};
    color: ${toggle2 === true ? "var(--color-pri)" : "var(--color-wht)"};
    opacity: ${toggle2 === true ? "1" : "0.5"};
    font-family: graphik;
  `
 const TitleBg = styled.div`
  background: var(--color-sec);
  position: absolute;
  top: 37.5%;
  left: 16%;
  z-index: -1;
  width: 5%;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    display: none;
  }

  @media ${({ theme }) => theme.mediaQueries.mobile_landscape} {
    display: none;
  }
  @media ${({ theme }) => theme.mediaQueries.ipad_pro}{
  top: 30.5%;
  left: 16%;
  width: 8%;
  }
  // @media ${({ theme }) => theme.mediaQueries.tablet_landscap}{
  //   top: 20.5%;
  //   left: 16%;
  // }
`
  return (
    <>
      <SectionWrapper
        style={{  zIndex: 2, position: "relative" }}
        data-scroll-section
      >
        <InsideSectionWrapper
          style={{
            
            alignItems: "flex-start",
            justifyContent: "flex-start",
            position: "absolute",
          }}
        >
          <BannerOverlaySubtext style={{ padding: "0% 3%" }}>
            Portfolio 
          </BannerOverlaySubtext>
          <TitleBg style={{color: '#2580eb'}}>
          #2580eb
          </TitleBg>
            <BannerOverlayText
              style={{ }}
              data-scroll
              data-scroll-speed="-2"
            >
              We back a vast range <br /> of enterprises pioneering <br /> new
              spaces.
            </BannerOverlayText>
          <ToggleWrapper data-scroll data-scroll-speed="-2">
            <BannerOverlayText0 onClick={() => handleToggle1()}>
              Products
            </BannerOverlayText0>
            <BannerOverlayText01 onClick={() => handleToggle2()}>
              Case Study
            </BannerOverlayText01>
          </ToggleWrapper>
        </InsideSectionWrapper>
      </SectionWrapper>

            {isMobile ?
            (<>
             
             {toggle1 === true ? (<div style={{color:"#fff",padding:"0px"}}>{LIST.map((i)=>(
              <MobileWrapper to={i.url}  key={i.id} >
             
               <MobileImgWrapper src={i.img} />     
               <CardNumberTextBackground style={{position : 'absolute', height:"50px", width:"50px", top : '-25px', left : '145px', zIndex :"6"}}>{i.step}</CardNumberTextBackground>
               <MobileProductWrapper >{i.product}</MobileProductWrapper>
              
             <MobileSectionWrapper >
            
              <p>{i.location}</p>
              <p>{i.sector}</p>
               </MobileSectionWrapper>
              
              </MobileWrapper>
            ))}
            </div>)
            :
            (<div style={{color:"#fff",padding:"0px"}}>{LIST2.map((i)=>(
              <MobileWrapper to={i.url}  key={i.id} >
             
               <MobileImgWrapper src={i.img} />     
               <CardNumberTextBackground style={{position : 'absolute', height:"50px", width:"50px", top : '-25px', left : '145px', zIndex :"6"}}>{i.step}</CardNumberTextBackground>
               <MobileProductWrapper >{i.product}</MobileProductWrapper>
              
             <MobileSectionWrapper >
            
              <p>{i.location}</p>
              <p>{i.sector}</p>
               </MobileSectionWrapper>
              
              </MobileWrapper>
            ))}
            </div>)}
            </>)
            
            :
            
            (toggle1 === true ? (
        <SectionWrapper style={{ }} data-scroll-section>
          <HorizontalScrollableSectionWrapper
            style={{
              height: "68vh",
              
            }}
          >
            {LIST.map((i, ind) => (
              <ContentWrapper2 key={i.id} to={i.url}>
                <CardImageWrapper>
                  <CardImg
                    style={{ height: "876",width:"1536", opacity: "0.65" }}
                    src={i.img}
                    alt=""
                  />
                </CardImageWrapper>
                <ContextWrapper>
                  <CardNumberTextBackground>{i.step}</CardNumberTextBackground>

                  <CardContent>
                    <ActionLinks>{i.sector}</ActionLinks>
                    <CardHeaderText2 style={{ marginBottom: "3rem", fontSize: ind === 4 ? '3rem' : '3.5rem' }}>
                      {i.product}
                    </CardHeaderText2>
                    <ActionLinks>{i.location}</ActionLinks>
                  </CardContent>
                </ContextWrapper>
              </ContentWrapper2>
            ))}
          </HorizontalScrollableSectionWrapper>
          <PrevNextWrapper>
            <ActionLinks2 left>prev</ActionLinks2>
            <ActionLinks2>next</ActionLinks2>
          </PrevNextWrapper>
        </SectionWrapper>
      ) : (
        <SectionWrapper style={{ height: "100vh" }} data-scroll-section>
          <HorizontalScrollableSectionWrapper
            style={{
              height: "68vh",
              columnGap: "18%",
              paddingLeft: "9%",
              paddingRight: "9%",
            }}
          >
            {LIST2.map((i) => (
              <ContentWrapper2 key={i.id} to={i.url}>
                <CardImageWrapper>
                  <CardImg
                    style={{ height: "876",width:"1536", opacity: "0.65" }}
                    src={i.img}
                    alt=""
                  />
                </CardImageWrapper>
                <ContextWrapper style={{top: "-95%"}}>
                  <CardNumberTextBackground>{i.step}</CardNumberTextBackground>

                  <CardContent>
                    <ActionLinks>{i.sector}</ActionLinks>
                    <CardHeaderText2 style={{ marginBottom: "3rem" }}>
                      {i.product}
                    </CardHeaderText2>
                    <ActionLinks>{i.location}</ActionLinks>
                  </CardContent>
                </ContextWrapper>
              </ContentWrapper2>
            ))}
          </HorizontalScrollableSectionWrapper>
        </SectionWrapper>
      ))}

      
    </>
  )
}

export default PortfolioContext

const ContextWrapper = styled.div`
  position: relative;
  z-index: 2;
  top: -103%;
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: flex-start;
  -webkit-box-align: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
  -webkit-align-content: flex-start;
  -ms-flex-line-pack: start;
  align-content: flex-start;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  @media ${({ theme }) => theme.mediaQueries.ipad_pro}{
    right: 0%;
    top: -82%;
  }
  @media ${({ theme }) => theme.mediaQueries.tablet}{
    top: -82%;
  }
`

const CardNumberTextBackground = styled.p`
  color: black;
  font-size: 0.8rem;
  font-family: "Roboto";
  text-transform: uppercase;
  font-weight: 600;
  -webkit-letter-spacing: 0.1rem;
  -moz-letter-spacing: 0.1rem;
  -ms-letter-spacing: 0.1rem;
  letter-spacing: 0.1rem;
  margin: 0rem;
  background: var(--color-pri);
  padding: 0.5rem;
  margin-left: -2rem;
  @media ${({ theme }) => theme.mediaQueries.mobile}{

  }
`

const CardContent = styled.div`
  position: relative;
  right: -65%;
  top: 58%;
  width: 42vh;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  word-break: break-word;
`

export const CardImageWrapper = styled.div`
  width: 100%;
  height: 68vh;
  min-width: fit-content;
  background: var(--color-blk);
  margin-top: 2%;
`

const ToggleWrapper = styled.div`
  display: flex;
  width: 30vh;
  height: auto;
  justify-content: space-between;
  align-items: baseline;
  margin-top: 16%;
  @media ${({ theme }) => theme.mediaQueries.mobile}{
    transform: rotate(90deg);
    margin-left: -35%;
    margin-top: 96%;
    
  }
  @media ${({ theme }) => theme.mediaQueries.mobile_landscape}{
    margin-top: 7%;
  }
  @media ${({ theme }) => theme.mediaQueries.ipad_pro}{
    margin-top: 40%;
  }
  @media ${({ theme }) => theme.mediaQueries.tablet}{
    transform: rotate(0deg);
    margin-top: 30%;
    margin-left: 10%;
  }
`

export const ActionLinks2 = styled.p`
  color: white;
  font-family: "Roboto";
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  margin-right: ${(props) => (props.left ? "7rem" : "0rem")};
  font-size: 0.7rem;
  font-weight: 400;

  // @media ${({ theme }) => theme.mediaQueries.mobile_landscape} {
  //   color: white;
  //   font-family: "Roboto";
  //   text-transform: uppercase;
  //   -webkit-letter-spacing: 0.1rem;
  //   -moz-letter-spacing: 0.1rem;
  //   -ms-letter-spacing: 0.1rem;
  //   letter-spacing: 0.1rem;
  //   margin-right: ${(props) => (props.left ? "7rem" : "2rem")};
  //   font-size: 0.6rem;
  //   font-weight: 400;
  // }
`

const PrevNextWrapper = styled.div`
  display: flex;
  width: auto;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 7%;
  right: 32%;
  @media ${({ theme }) => theme.mediaQueries.tablet}{
    display: none;
  }

`

 const InsideSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: flex-start;
  padding: 15% 18% 0% 15%;
  @media ${({ theme }) => theme.mediaQueries.mobile}{
    padding: 52% 0% 0% 8%;
  }

  @media ${({ theme }) => theme.mediaQueries.tablet}{
    padding: 38% 0% 0% 8%;
  }
  `


// const SectionWrapperPortPort = styled.div`
//   color: white;
//   display: flex;
//   width: 100%;
//   height: 100vh;
//   align-items: center;
//   justify-content: center;
//   background-color: var(--color-comp);
//   @media ${({ theme }) => theme.mediaQueries.mobile}{
//   }
// `
const MobileWrapper = styled(Link)`
  text-decoration: none !important;
  color: #fff;
  font-family: raphic;
  position : relative;
  padding-bottom : 50px;
  display : flex;
  justify-content : center;
  align-items : center;
  flex-direction : column;


`

const MobileImgWrapper = styled.img`
height:300px;
 width:300px;
 margin-left:70px;
`
const MobileProductWrapper = styled.p`
position : absolute;
top: 200px;
left : 100px;
z-index : 10;
color : white;
font-size : 35px;
font-weight:900;

`
const MobileSectionWrapper = styled.div`
textAlign : left;
width :50%;
padding-top : 12px;
fontSize: 0.9rem;
fontWeight: bold;

`
