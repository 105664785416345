import React from "react"
import styled from "styled-components"
import Case0 from "../../assets/images/case0.jpg"
import Case1 from "../../assets/images/case1.jpg"
import caseLogo1 from "../../assets/images/case-logo1.png"
import { scroll } from "../../utils/locomotiveScroll"
import case1 from "../../assets/images/case2 (1).jpg"
import case2 from "../../assets/images/case2 (2).jpg"
import case3 from "../../assets/images/case2 (3).jpg"
import case4 from "../../assets/images/case2 (4).jpg"
import case5 from "../../assets/images/case2 (5).jpg"
import case6 from "../../assets/images/case2 (6).jpg"

import case11 from "../../assets/images/case3 (1).jpg"
import case12 from "../../assets/images/case3 (2).jpg"
import case13 from "../../assets/images/case3 (3).jpg"
import case14 from "../../assets/images/case3 (4).jpg"
import case15 from "../../assets/images/case3 (5).jpg"
import { AddressContactText } from "../../building-blocks/atoms/containers/FooterContainer"

const LIST_IMAGE = [
  {
    src: case1,
  },
  {
    src: case2,
  },
  {
    src: case3,
  },
  {
    src: case4,
  },
  {
    src: case5,
  },
  {
    src: case6,
  },
]

const LIST_IMAGE2 = [
  {
    src: case11,
  },
  {
    src: case12,
  },
  {
    src: case13,
  },
  {
    src: case14,
  },
  {
    src: case15,
  },
]

export const BannerWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: flex-start;
  background-color: var(--color-blk);
`

export const BannerOverlayWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  background: linear-gradient(to left, rgba(255, 69, 0, 0.1), #ff4500);

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    justify-content: flex-start;
    padding: 40% 0%;
  }
`

export const BannerOverlayTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 38vh;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0% 12%;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    justify-content: center;
    height: 30vh;
  }
`
export const ActionFirst = styled.div`
  display: flex;
  width: 100%;
  height: 30vh;
  align-items: center;
  justify-content: space-between;
  padding: 0% 12%;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    font-weight: 400;
    font-family: roboto;
    font-size: 1.5rem;
    width: 100%;
    line-height: 2.4rem;
    margin: 0%;
  }
`

export const ActionSecond = styled.div`
  display: flex;
  width: 100%;
  height: 15vh;
  align-items: center;
  justify-content: center;
  padding: 0% 12%;
`

export const PrevNextWrapper = styled.div`
  display: flex;
  width: auto;
  align-items: center;
  justify-content: center;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    padding: 0% 12%;
  }
`

export const SubActWrapper = styled.div`
  display: flex;
  width: auto;
  align-items: center;
  justify-content: center;
`

export const TitleBg = styled.div`
  background: var(--color-sec);
  width: 6%;
  height: 1.2rem;
  position: relative;
  top: -3.5%;
  left: -1.5%;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    display: none;
  }
`

///////////////////////////////////////////////

export const BannerOverlaySubtext = styled.p`
  color: white;
  font-family: roboto;
  font-size: 0.8rem;
  text-transform: capitalize;
  letter-spacing: 0.3rem;
  font-weight: 600;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    z-index: 1;
    font-size: 0.7rem;
    letter-spacing: 0.2rem;
    opacity: 0.8;
  }
`

export const BannerOverlayText = styled.h1`
  color: white;
  font-family: graphik;
  font-size: 3.2rem;
  letter-spacing: 0.1rem;
  font-weight: 700;
  width: 40%;
  margin-top: 2rem;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    font-weight: 800;
    font-size: 2rem;
    width: 100%;
    line-height: 3.5rem;
    margin-top: 2rem;
  }
`

export const BannerOverlayText2 = styled.h1`
  width: 100%;
  font-weight: 300;
  font-family: roboto;
  margin-top: 10rem;
  font-size: 3.2rem;
  color: black;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    font-weight: 400;
    font-family: roboto;
    font-size: 1.2rem;
    width: 100%;
    line-height: 2.4rem;
    margin: 0%;
    letter-spacing: 0.08rem;
  }
`

export const ActionLinks = styled.p`
  color: white;
  font-size: 0.8rem;
  font-family: "Roboto";
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0.1rem;
  @media ${({ theme }) => theme.mediaQueries.tablet}{
    margin-bottom: 5rem; 
  }
`

export const ActionLinks2 = styled.p`
  color: white;
  font-family: "Roboto";
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  margin-right: ${(props) => (props.left ? "7rem" : "0rem")};
  font-size: 0.7rem;
  font-weight: 400;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    text-transform: none;
    font-size: 0.8rem;
    font-weight: 400;
    line-height: 2;
    width: 92%;
    letter-spacing: 0.08rem;
  }
`

////////////////////////////////////////////

export const BannerImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    height: 100%;
    width: 100%;
    margin-top: 0%;
    object-fit: cover;
    margin-right: 0%;
  }
`

/////////////////////////////////////////////////

export const SectionWrapper1 = styled.div`
  color: white;
  display: flex;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
  background-color: var(--color-comp);
  height: 90vh;
  z-index: 2;
`

export const SectionWrapper2 = styled.div`
  color: white;
  display: flex;
  width: 100%;
  height: 120vh;
  align-items: center;
  justify-content: center;
  background-color: var(--color-comp);

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    height: 100vh;
  }
`

export const BackImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    height: 100%;
    width: auto;
  }
`

export const InsideSectionWrapper1 = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 15% 18% 0% 15%;
`

export const BannerOverlayText1 = styled.h1`
  color: white;
  font-family: graphik;
  font-size: 3.5rem;
  letter-spacing: 0.1rem;
  font-weight: 700;
  width: 90%;
  margin-top: 6rem;
`

export const BannerOverlaySubtext1 = styled.p`
  color: white;
  font-family: roboto;
  font-size: 0.8rem;
  text-transform: uppercase;
  letter-spacing: 0.3rem;
  font-weight: 600;
  padding: 0% 3%;
`

export const SectionWrapper3 = styled.div`
  color: white;
  display: flex;
  width: 100%;
  height: auto;
  align-items: center;
  justify-content: center;
  background-color: var(--color-comp);
  height: 120vh;
  z-index: 2;
  position: relative;

  padding: 12% 20%;
  background-color: rgb(225, 230, 236);
  justify-content: space-between;
  align-items: center;
  flex-direction: row;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    padding: 30% 12%;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column-reverse;
  }
`

export const ServicesWrapper1 = styled.div`
  width: 100%;
  height: inherit;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 6% 30% 14%;
  background-color: var(--color-wht);
  position: absolute;

  padding: 0;
  width: 52%;
  position: unset;
  background-color: transparent;
  justify-content: center;
  align-items: flex-start;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    width: 100%;
    height: auto;
    margin-bottom: 12vh;
  }
`

export const BannerOverlayText3 = styled.h1`
  color: white;
  font-family: roboto;
  font-size: 3.2rem;
  letter-spacing: 0.1rem;
  font-weight: 300;
  margin-top: 5rem;
  width: 100%;
  color: black;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    font-weight: 400;
    font-family: roboto;
    font-size: 1.2rem;
    width: 60%;
    line-height: 2.4rem;
    margin: 0%;
    letter-spacing: 0.08rem;
  }
`

export const ActionLinks1 = styled.p`
  color: white;
  font-family: "Roboto";
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0.1rem;

  text-transform: none;
  line-height: 2;
  font-weight: 300;
  width: 100%;
  letter-spacing: 0.02rem;
  color: black;
  margin-top: 3rem;
  font-size: 1rem;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    text-transform: none;
    font-size: 0.8rem;
    font-weight: 400;
    line-height: 2;
    font-weight: 300;
    width: 92%;
    letter-spacing: 0.08rem;
  }
`

export const SectionWrapper = styled.div`
  color: white;
  display: flex;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
  background-color: var(--color-comp);
`

export const SectionWrapper10 = styled.div`
  color: white;
  display: flex;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
  background-color: var(--color-comp);

  height: 530vh;
  z-index: 6;
  position: relative;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    height: 130vh;
    z-index: 6;
    position: relative;
  }
`

export const SectionWrapper9 = styled.div`
  color: white;
  display: flex;
  width: 100%;
  height: 160vh;
  align-items: center;
  justify-content: center;
  background-color: rgb(225, 230, 236);

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    align-items: flex-start;
    height: 120vh;
  }
`

export const SectionWrapper6 = styled.div`
  color: white;
  display: flex;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
  background-color: var(--color-comp);

  height: 172vh;
  z-index: 2;
  position: relative;
`

export const SectionWrapper7 = styled.div`
  color: white;
  display: flex;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
  background-color: var(--color-comp);

  height: 530vh;
  z-index: 6;
  position: relative;
`

export const SectionWrapper4 = styled.div`
  color: white;
  display: flex;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
  background-color: rgb(225, 230, 236);

  height: 80vh;
  z-index: 2;
  position: relative;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    height: 55vh;
  }
`

export const SectionWrapper5 = styled.div`
  color: white;
  display: flex;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
  background-color: rgb(225, 230, 236);

  height: 100vh;
  z-index: 2;
  position: relative;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    height: 80vh;
  }
`

export const SectionWrapper8 = styled.div`
  color: white;
  display: flex;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
  background-color: rgb(225, 230, 236);

  height: 90vh;
  z-index: 2;
  position: relative;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    height: 55vh;
  }
`

export const ServicesWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: inherit;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  background-color: rgb(225, 230, 236);
  padding: 14% 18% 14% 24%;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    padding: 30% 12%;
    justify-content: center;
  }
`

export const ServicesWrapper3 = styled.div`
  position: absolute;
  width: 100%;
  height: inherit;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  background-color: rgb(225, 230, 236);
  padding: 5% 18% 14% 24%;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    padding: 30% 12%;
    justify-content: center;
  }
`

export const ServicesWrapper2 = styled.div`
  position: absolute;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  background-color: rgb(225, 230, 236);
  padding: 0% 18% 14% 24%;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    padding: 10% 12%;
    justify-content: center;
  }
`

const GridWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  height: auto;
  z-index: 2;
  flex-wrap: wrap;
  flex-direction: column;
  position: absolute;
  background-color: rgb(225, 230, 236);

  height: 100%;
  padding: 0% 18%;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;

  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    padding: 7%;
  }
`

const Img = styled.img`
  width: 30%;
  padding: 2%;
  height: auto;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    width: 48%;
    padding: 4%;
    height: auto;
  }
`

const ImgWrapper = styled.div`
  display: flex;
  width: 100%;
  z-index: 2;
  position: absolute;
  background-color: rgb(225, 230, 236);
  height: 100%;
  padding: 0% 10%;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  position: absolute;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    align-items: center;
    justify-content: center;
  }
`

const Img2 = styled.img`
  width: 100%;
  height: auto;
  margin-bottom: 9%;
`

const LogoImage = styled.img`
  width: 22%;
  height: auto;
  object-fit: cover;&:hover {
    background-color: #f94940;
  }

  

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    width: 60%;
    height: auto;
    object-fit: cover;
  }
`

function CartzsContext() {
  React.useEffect(() => {
    scroll()
  }, [])

  return (
    <>
      <BannerWrapper data-scroll-section>
        <BannerImg src={Case0} alt="" data-scroll data-scroll-speed="-3" />
        <BannerOverlayWrapper>
          <BannerOverlayTextWrapper data-scroll data-scroll-speed="-2">
            <BannerOverlaySubtext style={{ zIndex: "2" }}>
              Industry - Ecommerce marketplace
            </BannerOverlaySubtext>

            <BannerOverlayText>Cartzs</BannerOverlayText>
          </BannerOverlayTextWrapper>
          <>
            <ActionFirst data-scroll data-scroll-speed="-1">
              <SubActWrapper>
                <ActionLinks>UI / UX, Website, App</ActionLinks>
              </SubActWrapper>
              {/* <PrevNextWrapper>
                <ActionLinks2 left>prev</ActionLinks2>
                <ActionLinks2>next</ActionLinks2>
              </PrevNextWrapper> */}
            </ActionFirst>
            <ActionSecond
              style={{
                position: "absolute",
                bottom: "-5rem",
              }}
              data-scroll
              data-scroll-speed="-2"
            >
              <SubActWrapper>
                <ActionLinks style={{ letterSpacing: "0.28rem" }}>
                  scroll down
                </ActionLinks>
              </SubActWrapper>
            </ActionSecond>
          </>
        </BannerOverlayWrapper>
      </BannerWrapper>

      <SectionWrapper3 data-scroll-section>
        <LogoImage src={caseLogo1} alt="" />
        <ServicesWrapper1>
          <>
            <BannerOverlayText3 data-scroll data-scroll-speed="1">
              All products under one roof
            </BannerOverlayText3>
          </>
          <ActionLinks1 data-scroll data-scroll-speed="1">
            An upcoming global marketplace with wide range of local and
            international products. Supporting sellers with tips, tricks and
            ideas to reach more customers by presenting their products
            effectively and efficiently.
          </ActionLinks1>
          <AddressContactText target="_blank" href="https://www.cartzs.com/" style={{color: '#333', fontWeight: 'bold'}}>
              VISIT WEBSITE &rarr;
            </AddressContactText>
        </ServicesWrapper1>
      </SectionWrapper3>

      <SectionWrapper2 data-scroll-section>
        <BackImg
          src={Case1}
          style={{ height: "130vh" }}
          alt=""
          data-scroll
          data-scroll-speed="-5"
        />
      </SectionWrapper2>

      <SectionWrapper8 data-scroll-section style={{ marginBlock: "-0.1rem" }}>
        <ServicesWrapper>
          <>
            <BannerOverlayText2 data-scroll data-scroll-speed="1">
              Prioritize your products to sell easily with CARTZS marketplace.
              <br />
              <br />
              Enhance Product Visibility along with an easy Customer Reach. 
            </BannerOverlayText2>
          </>
        </ServicesWrapper>
      </SectionWrapper8>

      <SectionWrapper9 data-scroll-section style={{ marginBlock: "-0.1rem" }}>
        <GridWrapper data-scroll data-scroll-speed="2">
          {LIST_IMAGE.map((i) => (
            <Img src={i.src} alt="" />
          ))}
        </GridWrapper>
      </SectionWrapper9>

      <SectionWrapper4 data-scroll-section style={{ marginBlock: "-0.5rem" }}>
        <ServicesWrapper3>
          <>
            <BannerOverlayText2 data- data-scroll data-scroll-speed="1">
              Intuitive and user friendly design approach.
            </BannerOverlayText2>

            <ActionLinks1 data-scroll data-scroll-speed="1">
              We were tasked to keep the users focus on their exercises. This
              challenge drive us to come up with relaxing color variations,
              smooth animations, and combine it with carefully curated fonts.
            </ActionLinks1>
          </>
        </ServicesWrapper3>
      </SectionWrapper4>

      <SectionWrapper10 data-scroll-section style={{ marginBlock: "-0.1rem" }}>
        <ImgWrapper>
          {LIST_IMAGE2.map((i) => (
            <Img2 src={i.src} alt="" />
          ))}
        </ImgWrapper>
      </SectionWrapper10>

      <SectionWrapper5 data-scroll-section style={{ marginBlock: "-0.1rem" }}>
        <ServicesWrapper2>
          <>
            <BannerOverlayText2 data-scroll data-scroll-speed="1">
              By the year 2021, worldwide retail ecommerce sales will reach $4.9
              trillion
              <br />
              <br />
              Global marketplace platform provider revenues are expectedto more
              than double from $18.7 billion in 2017 to $40.1 billionin 2022,
              driven by the sharing economy.
            </BannerOverlayText2>
          </>
        </ServicesWrapper2>
      </SectionWrapper5>
    </>
  )
}

export default CartzsContext
