import locomotiveScroll from "locomotive-scroll"
import React from "react"
import { Link } from "react-router-dom"
import styled from "styled-components"
// import MenuLink from "../Navigation/MenuLink"
const LINKS = [
  {
    id: 1,
    link: "portfolio",
    url: "/portfolio",
  },
  {
    id: 2,
    link: "platform",
    url: "/platform",
  },
  {
    id: 3,
    link: "potential",
    url: "/potential",
  },
  {
    id: 4,
    link: "prospect",
    url: "/prospect",
  },
]

const LogoWrapper = styled(Link)`
  text-decoration: none;
  color: white;
  position: fixed;
  font-family: roboto;
  font-size: 1.4rem;
  text-transform: capitalize;
  letter-spacing: 0.05rem;
  font-weight: 600;
  mix-blend-mode: difference;
  transition: all var(--speed);
  top: 8.25vh;
  left: 8%;
  &:hover {
    color: var(--color-pri);
  }

  &:focus {
    color: var(--color-pri);
  }

  &:active {
    color: var(--color-pri);
  }

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    font-size: 1.2rem;
    position: absolute;
    top: 70px;
    left: 40px;
  }

  @media ${({ theme }) => theme.mediaQueries.mobile_landscape} {
    font-size: 1.1rem;
  }
  @media ${({ theme }) => theme.mediaQueries.ipad_pro} {
    top: 80px;
  }
  @media ${({ theme }) => theme.mediaQueries.tablet} {
    top: 85px;
  }
`

const LinkWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    display: none;
  }

  @media ${({ theme }) => theme.mediaQueries.mobile_landscape} {
    display: none;
  }

  @media ${({ theme }) => theme.mediaQueries.tablet} {
    display: block;
  }
`

const Links = styled(Link)`
  color: white;
  font-size: 0.8rem;
  font-family: "Roboto";
  text-transform: uppercase;
  font-weight: 500;
  margin-right: 3rem;
  text-decoration: none;
  transition: all var(--speed);
  opacity: 0.8;
  padding: 0.3rem;

  &:hover {
    color: var(--color-pri);
    opacity: 1;
  }

  &:focus {
    color: var(--color-pri);
    opacity: 1;
  }

  &:active {
    color: var(--color-pri);
    opacity: 1;
  }

  @media ${({ theme }) => theme.mediaQueries.tablet} {
    margin-right: 2rem;
  }
`

const HeaderWrapper = styled.div`
  position: absolute;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  padding: 5% 12% 4% 4%;
  z-index: 10;

  height: ${(props) => (props.path === "home" ? "37.5vh" : "32vh")};

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    height: 20vh;
    padding: 12%;
  }

  @media ${({ theme }) => theme.mediaQueries.mobile_landscape} {
    height: 18vh;
    padding: 7% 5%;
  }
  @media ${({ theme }) => theme.mediaQueries.ipad_pro} {
    height: 30vh;
    padding: 3% 5%;
  }
  @media ${({ theme }) => theme.mediaQueries.tablet} {
    padding: 3% 0%;
  }
  @media ${({ theme }) => theme.mediaQueries.tablet_landscape} {
    padding: 3% 0%;
  }
`

const Header = ({ logoOnly }) => {
  const [isMobile, setisMobile] = React.useState(null)
  const [path, setPath] = React.useState()
  // const [isIpadpro, setisIpadro] = React.useState(null)
  const changeMobile = () => {
    window.matchMedia("(max-width: 37.5em)").matches
      ? setisMobile(true)
      : setisMobile(false)
  }
  // const changeIpadpro = () => {
  //   window.matchMedia("(max-width: 37.5em)").matches
  //     ? setisIpadro(true)
  //     : setisIpadro(false)
  // }

  const scrollTop = () => {
    const target = document.querySelector("#target")
    const scroll = new locomotiveScroll({ target, Smooth: true })
    // window.location.href = "http://localhost:3000/"
  }

  React.useEffect(() => {
    changeMobile()
    const pathName = () =>
      window.location.pathname
        .slice(1)
        .replace(/-/g, " ")
        .toLowerCase()
        .replace(/(\b[a-z](?!\s))/g, function (item) {
          return item.toLowerCase()
        })
    setPath(pathName() + "home")
    window.addEventListener("resize", changeMobile)
    return () => window.removeEventListener("resize", changeMobile)
  }, [])

  return (
    <div
      style={{ display: "flex", justifyContent: "space-between" }}
      id="target"
    >
      <LogoWrapper to="/" style={{ zIndex: "99" }} onClick={scrollTop}>
        Honeysys
      </LogoWrapper>
      <HeaderWrapper
        path={path}
        data-scroll-section
        data-scroll
        data-scroll-speed="3"
      >
        {logoOnly ? (
          <></>
        ) : (
          <>
            {isMobile ? (
              <></>
            ) : (
              <LinkWrapper>
                {LINKS.map((i) => (
                  <Links to={i.url} key={i.id}>
                    {i.link}
                  </Links>
                ))}
              </LinkWrapper>
            )}
          </>
        )}
      </HeaderWrapper>
    </div>
  )
}

export default Header
