// import { Link } from "react-router-dom"
import styled from "styled-components"

export const BannerOverlaySubtext = styled.p`
  color: white;
  font-family: roboto;
  font-size: 0.8rem;
  text-transform: uppercase;
  letter-spacing: 0.3rem;
  font-weight: 600;
`

export const BannerOverlaySubtext1 = styled.p`
  color: white;
  font-family: roboto;
  font-size: 0.8rem;
  text-transform: uppercase;
  letter-spacing: 0.3rem;
  font-weight: 600;
  padding: 0% 3%;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    padding: 0% 3%;
    font-size: 0.7rem;
    opacity: 0.8;
    letter-spacing: 0.28rem;
  }
`

export const BannerOverlayText = styled.h1`
  color: white;
  font-family: graphik;
  font-size: 3.5rem;
  letter-spacing: 0.1rem;
  font-weight: 700;
  width: 40%;
  margin-top: 4rem;
`

export const BannerOverlayText1 = styled.h1`
  color: white;
  font-family: graphik;
  font-size: 3.5rem;
  letter-spacing: 0.1rem;
  font-weight: 700;
  width: 90%;
  margin-top: 6rem;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    font-size: 1.5rem;
    font-weight: 800;
    width: 100%;
    line-height: 2.5rem;
    margin-top: 3rem;
    margin-bottom: 5rem;
  }
  @media ${({ theme }) => theme.mediaQueries.tablet} {
    width: 70%;
  }
  @media ${({ theme }) => theme.mediaQueries.tablet_landscape} {
    // display: none;
    width: 100%;
  }
`

export const ActionLinks = styled.p`
  color: white;
  font-size: 0.8rem;
  font-family: "Roboto";
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0.1rem;
`

export const ActionLinks2 = styled.p`
  color: white;
  font-size: 0.8rem;
  font-family: "Roboto";
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0.1rem;
  width: 100%;
  font-weight: 500;
  letter-spacing: 0.12rem;
  padding: 2rem 0rem 1.8rem 0rem;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    color: white;
    width: 100%;
    padding: 2rem 0rem 1.8rem;
    font-weight: 400;
    font-size: 0.7rem;
    opacity: 0.8;
    letter-spacing: 0.28rem;
  }
`

export const ActionLinks3 = styled.p`
  color: white;
  font-size: 1.2rem;
  font-family: "Roboto";
  text-transform: capitalize;
  font-weight: 600;
  letter-spacing: 0.1rem;
  width: 100%;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    color: white;
    width: 100%;
    font-weight: 500;
    font-size: 1.2rem;
    text-transform: none;
    line-height: 2.2rem;
  }
`

export const ActionLinks1 = styled.p`
  color: white;
  font-size: 0.8rem;
  font-family: "Roboto";
  text-transform: none;
  font-weight: 400;
  letter-spacing: 0.08rem;
  width: 52%;
  line-height: 2;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    text-transform: none;
    line-height: 2;
    font-weight: 300;
    width: 92%;
    letter-spacing: 0.08rem;
  }
`
