import styled from "styled-components"

export const BannerImg = styled.img`
  width: 94%;
  margin-right: -0.2rem;
  object-fit: cover;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    height: 75%;
    width: 100%;
    margin-top: -18%;
    margin-right: 0%;
  }

  @media ${({ theme }) => theme.mediaQueries.mobile_landscape} {
    height: 75%;
    width: 78%;
    margin-top: 0%;
    margin-right: 0%;
  }
  @media ${({ theme }) => theme.mediaQueries.ipad_pro}{
    height: 100vh;
    width: 100%;
    object-fit: cover;
  }
  @media ${({ theme }) => theme.mediaQueries.tablet}{
    margin-top: 4rem;
    height: 60vh;
    width: 80vh;
    object-fit: cover;
  }
`
