import React from "react"
import {
  SectionWrapper,
  SayWrapper,
  SubActWrapper,
  ServicesWrapper2,
  SectionWrapperImg,
} from "../../building-blocks/atoms/containers/ConsultingServicesContainer"
import {
  ActionLink,
  ActionLinks2,
  BannerOverlayText,
} from "../../building-blocks/atoms/texts/ConsultingServicesText"
import ServicesImg from "../../assets/images/services.jpg"
import RightWhite from "../../assets/icons/right_white.png"

const ConsultingServices = () => {
  const [isMobile, setisMobile] = React.useState(null)

  const changeMobile = () => {
    window.matchMedia("(max-width: 37.5em)").matches
      ? setisMobile(true)
      : setisMobile(false)
  }

  React.useEffect(() => {
    changeMobile()
    window.addEventListener("resize", changeMobile)
    return () => window.removeEventListener("resize", changeMobile)
  }, [])

  return (
    <SectionWrapper data-scroll-section id="fixed-elements">
      <SectionWrapperImg
        style={{
          backgroundImage: `url(${ServicesImg})`,
        }}
        data-scroll
        data-scroll-speed="-5"
      ></SectionWrapperImg>

      <ServicesWrapper2 data-scroll data-scroll-speed="1">
        <SayWrapper>
          <ActionLinks2>consulting services</ActionLinks2>
        </SayWrapper>
        <>
          <BannerOverlayText>Passionate manpower providers</BannerOverlayText>
        </>
        {isMobile ? (
          <>
            <SubActWrapper
              data-scroll
              data-scroll-speed="2"
              style={{
                width: "100%",
                justifyContent: "flex-start",
                margin: "0%",
                marginTop: "2rem",
              }}
            >
              <ActionLink
                to="/consulting-services"
                style={{
                  alignItems: "center",
                  justifyContent: "flex-start",
                  display: "flex",
                  width: "100%",
                }}
              >
                explore more{" "}
                <img
                  src={RightWhite}
                  style={{
                    marginLeft: "0.5rem",
                    width: "7%",
                    height: "auto",
                    objectFit: "cover",
                  }}
                  alt=""
                />
              </ActionLink>
            </SubActWrapper>
          </>
        ) : (
          <>
            <SubActWrapper>
              <div
                style={{
                  background: "#ACE200",
                  height: "1px",
                  width: "3.5rem",
                  marginRight: "5px",
                }}
              ></div>
              <ActionLink to="/consulting-services">explore more</ActionLink>
            </SubActWrapper>
          </>
        )}
      </ServicesWrapper2>
    </SectionWrapper>
  )
}

export default ConsultingServices
