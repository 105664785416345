import React from "react"
import styled from "styled-components"
// import { Link } from "react-scroll"
import {
  // InsideSectionWrapper,
  InsideSectionWrapper1,
  SectionWrapper,
  SectionWrapper1,
  InsideSectionWrapper2,
} from "../../building-blocks/atoms/containers/ContactUsContextContainer"
import {
  // ActionLinks,
  ActionLinks1,
  // BannerOverlaySubtext,
  BannerOverlaySubtext1,
  // BannerOverlayText,
  BannerOverlayText3,
  BannerOverlayText1,
  BannerOverlayText2,
  InputText,
  ActionLinks2,
} from "../../building-blocks/atoms/texts/ContactUsContextText"
import { scroll } from "../../utils/locomotiveScroll"

function ContactUsContext() {
  React.useEffect(() => {
    scroll()
  }, [])

  // const [links, setLinks] = React.useState()

  // const Linked = styled(Link)`
  //   color: white;
  //   font-weight: 400;
  //   font-family: roboto;
  //   font-size: 0.8rem;
  //   padding: 1rem;
  //   width: auto;
  //   text-align: right;
  //   cursor: pointer;
  // `

  const Linked = styled.p`
    color: white;
    font-weight: 400;
    font-family: roboto;
    font-size: 0.8rem;
    padding: 1rem;
    width: auto;
    text-align: right;
    cursor: pointer;
  `

  const LinkWrapper = styled.div`
    position: absolute;
    right: 9%;
    display: flex;
    flex-direction: column;
    width: auto;
    height: auto;
    justify-content: center;
    align-items: flex-end;
    border-right: 1px solid var(--color-wht);
    z-index: 6;
    // top: 32%;
  `

  const CompletionWrapper = styled.div`
    position: absolute;
    right: 9%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    width: auto;
    height: auto;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-items: flex-end;
    -webkit-box-align: flex-end;
    -ms-flex-align: flex-end;
    align-items: flex-end;
    /* border-right: 1px solid var(--color-wht); */
    z-index: 6;
    bottom: 1%;
  `

  const CompletionPercent = styled.p`
    color: white;
    font-family: roboto;
    font-size: 1.8rem;
    -webkit-letter-spacing: 0.1rem;
    -moz-letter-spacing: 0.1rem;
    -ms-letter-spacing: 0.1rem;
    /* letter-spacing: 0.1rem; */
    font-weight: 600;
    width: auto;
    /* margin-top: 5rem; */
    text-align: right;
  `

  const completionSubText = styled.p`
    color: white;
    font-family: roboto;
    font-size: 0.7rem;
    text-transform: uppercase;
    -webkit-letter-spacing: 0.3rem;
    -moz-letter-spacing: 0.3rem;
    -ms-letter-spacing: 0.3rem;
    letter-spacing: 0.1rem;
    font-weight: 600;
  `

  const BannerOverlaySubtextNum = styled.p`
    position: absolute;
    font-size: 5rem;
    font-weight: 700;
    top: 34%;
    left: 14%;
    letter-spacing: 0rem;
    font-family: "graphik";
    color: var(--color-sec);
  `

  return (
    <>
      <SectionWrapper1 data-scroll-section>
        <InsideSectionWrapper1>
          <BannerOverlaySubtext1>
            Please help us to serve you better
          </BannerOverlaySubtext1>
          <>
            <BannerOverlayText1>Contact Us</BannerOverlayText1>
          </>
          <>
            <BannerOverlayText2>
              Get in touch with us & <br />
              We will get back to you
            </BannerOverlayText2>
          </>

          <ActionLinks1>continue</ActionLinks1>
        </InsideSectionWrapper1>

        <LinkWrapper
        // data-scroll
        // data-scroll-sticky
        // data-scroll-target="#c-fixed_wrapper"
        >
          <Linked
            activeClass="active"
            to="section1"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
          >
            Step 1
          </Linked>
          <Linked
            activeClass="active"
            to="section2"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
          >
            Step 2
          </Linked>
          <Linked
            activeClass="active"
            to="section3"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
          >
            Step 3
          </Linked>
          <Linked
            activeClass="active"
            to="section4"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
          >
            Step 4
          </Linked>
          <Linked
            activeClass="active"
            to="section5"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
          >
            Step 5
          </Linked>
        </LinkWrapper>

        <CompletionWrapper>
          <CompletionPercent>10%</CompletionPercent>
          <completionSubText>Completed</completionSubText>
        </CompletionWrapper>
      </SectionWrapper1>

      <SectionWrapper id="section1" data-scroll-section>
        <InsideSectionWrapper2>
          <BannerOverlaySubtextNum>01.</BannerOverlaySubtextNum>
          <>
            <BannerOverlayText3>What's your Full Name?*</BannerOverlayText3>
          </>

          <>
            <InputText type="text" placeholder="Type your answer here..." />
          </>
        </InsideSectionWrapper2>
      </SectionWrapper>

      <SectionWrapper id="section2" data-scroll-section>
        <InsideSectionWrapper2>
          <BannerOverlaySubtextNum>02.</BannerOverlaySubtextNum>
          <>
            <BannerOverlayText3>
              Hey! <br />
              Which Email Address shall we use?*
            </BannerOverlayText3>
          </>

          <>
            <InputText type="text" placeholder="name@example.com" />
          </>
        </InsideSectionWrapper2>
      </SectionWrapper>

      <SectionWrapper id="section3" data-scroll-section>
        <InsideSectionWrapper2>
          <BannerOverlaySubtextNum>03.</BannerOverlaySubtextNum>
          <>
            <BannerOverlayText3>What is your Phone Number?</BannerOverlayText3>
          </>

          <>
            <InputText type="text" placeholder="0000-0000-0000" />
          </>
        </InsideSectionWrapper2>
      </SectionWrapper>

      <SectionWrapper id="section4" data-scroll-section>
        <InsideSectionWrapper2>
          <BannerOverlaySubtextNum>04.</BannerOverlaySubtextNum>
          <>
            <BannerOverlayText3>
              What is the best time to connect?
            </BannerOverlayText3>
          </>

          <>
            <InputText type="text" placeholder="00:00 Hrs" />
          </>
        </InsideSectionWrapper2>
      </SectionWrapper>

      <SectionWrapper id="section5" data-scroll-section>
        <InsideSectionWrapper2>
          <BannerOverlaySubtextNum>05.</BannerOverlaySubtextNum>
          <>
            <BannerOverlayText3>Tell us about your project</BannerOverlayText3>
          </>

          <>
            <InputText type="text" placeholder="Type your answer here..." />
          </>

          <ActionLinks2>SUBMIT</ActionLinks2>
        </InsideSectionWrapper2>
      </SectionWrapper>
    </>
  )
}

export default ContactUsContext
