// import { Link } from "react-router-dom"
import styled from "styled-components"

export const BannerOverlaySubtext = styled.p`
  color: white;
  font-family: roboto;
  font-size: 0.8rem;
  text-transform: uppercase;
  letter-spacing: 0.3rem;
  font-weight: 600;
`

export const BannerOverlaySubtext1 = styled.p`
  color: white;
  font-family: roboto;
  font-size: 0.8rem;
  text-transform: uppercase;
  letter-spacing: 0.3rem;
  font-weight: 600;
  padding: 0% 3%;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    font-size: 0.7rem;
    opacity: 0.8;
    letter-spacing: 0.28rem;
  }
`

export const BannerOverlayText = styled.h1`
  color: white;
  font-family: graphik;
  font-size: 3.5rem;
  letter-spacing: 0.1rem;
  font-weight: 500;
  width: 70%;
  margin-top: 4rem;
  text-align: right;

  @media ${({ theme }) => theme.mediaQueries.ipad_pro}{
    margin-top: 4rem;
  }
  
`

export const BannerOverlayText4 = styled.h1`
  color: white;
  font-family: graphik;
  font-size: 2.8rem;
  letter-spacing: 0.1rem;
  font-weight: 400;
  width: 100%;
  margin-top: 6rem;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    font-weight: 400;
    /* font-size: 2.8rem; */
    font-size: 1.5rem;
    /* font-weight: 800; */
    width: 100%;
    line-height: 2.5rem;
    margin-top: 4rem;
    margin-bottom: 4rem;
  }
`

export const BannerOverlayText3 = styled.h1`
  color: white;
  font-family: roboto;
  font-size: 3.8rem;
  letter-spacing: 0.1rem;
  font-weight: 500;
  width: 40%;
  margin-top: 4rem;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    font-size: 1.5rem;
    font-weight: 800;
    width: 100%;
    line-height: 2.5rem;
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
  @media ${({ theme }) => theme.mediaQueries.tablet_landscape}{
    margin-top: 2rem;
  }
  @media ${({ theme }) => theme.mediaQueries.tablet}{
    width: 40%;
    font-size: 2  .8rem;
  }
`

export const BannerOverlayText2 = styled.h1`
  color: white;
  font-family: roboto;
  font-size: 3.2rem;
  letter-spacing: 0.1rem;
  font-weight: 300;
  width: 100%;
  margin-top: 6rem;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    font-weight: 300;
    font-family: roboto;
    /* font-size: 3.2rem; */
    font-size: 1.5rem;
    /* font-weight: 800; */
    width: 100%;
    line-height: 2.5rem;
    margin-top: 3rem;
    margin-bottom: 5rem;
  }
`

export const BannerOverlayText1 = styled.h1`
  color: white;
  font-family: graphik;
  font-size: 3.8rem;
  letter-spacing: 0.1rem;
  font-weight: 700;
  width: 90%;
  margin-top: 6rem;
  text-transform: uppercase;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    text-transform: uppercase;
    font-weight: 800;
    font-size: 2rem;
    width: 100%;
    line-height: 3.5rem;
    margin-top: 18vh;
  }
`

export const ActionLinks = styled.p`
  color: white;
  font-size: 0.8rem;
  font-family: "Roboto";
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0.1rem;
`

export const ActionLinks2 = styled.p`
  color: white;
  font-size: 0.8rem;
  font-family: "Roboto";
  font-weight: 400;
  letter-spacing: 0.08rem;
  line-height: 2;
  width: 100%;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    text-transform: none;
    line-height: 2;
    font-weight: 300;
    width: 92%;
    letter-spacing: 0.08rem;
  }
`

export const ActionLinks1 = styled.p`
  color: white;
  font-size: 1.5rem;
  font-family: "Roboto";
  font-weight: 400;
  letter-spacing: 0.08rem;
  line-height: 2;
  width: 55%;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    text-transform: none;
    line-height: 2;
    font-weight: 300;
    width: 92%;
    letter-spacing: 0.08rem;
    font-size: 0.8rem;
  }
`
