import styled from "styled-components"

export const SectionWrapper = styled.div`
  color: white;
  display: flex;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
  background-color: var(--color-comp);

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    height: 70vh;
  }

  @media ${({ theme }) => theme.mediaQueries.mobile_landscape} {
    padding: 6% 32% 6% 34%;
  }
  @media ${({ theme }) => theme.mediaQueries.ipad_pro}{
    height: 70vh;
  }
  
  
  
`
export const ServicesWrapper = styled.div`
  position: absolute;
  // width: 100%;
  height: inherit;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 6% 12%;

  background-color: white;
  padding: 14% 42%;
  align-items: center;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    padding: 50% 0%;
    align-items: flex-end;
  }

  @media ${({ theme }) => theme.mediaQueries.mobile_landscape} {
    padding: 6% 32% 6% 34%;
    align-items: center;
    justify-content: center;
  }
  @media ${({ theme }) => theme.mediaQueries.tablet}{
    padding: 40% 19%;
  }
`
export const LWrapper = styled.div`
background: #ace31c;
height: 1px;
width: 7.4rem;
margin-right: "3rem;

@media ${({ theme }) => theme.mediaQueries.mobile}{
  height: 1px;
  width: 6.4rem;
  margin-right: 1rem;
  margin-bottom: 7rem;
  margin-left: 2rem;
}
`

