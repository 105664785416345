import React from "react"
import Header from "../../common-components/Header/Header"
import ContactUsContext from "../../common-components/SectionComponents/ContactUsContext"
// import MenuLink from "../../common-components/Navigation/MenuLink"
// import MenuPage from "../menu-page/MenuPage"
import { scroll } from "../../utils/locomotiveScroll"

function ContactUsPage() {
  const [menuOpen] = React.useState(false)
  const [isMobile, setisMobile] = React.useState(null)

  const changeMobile = () => {
    window.matchMedia("(max-width: 37.5em)").matches
      ? setisMobile(true)
      : setisMobile(false)
  }

  React.useEffect(() => {
    window.scroll(0, 0)
    scroll()
    changeMobile()
    window.addEventListener("resize", changeMobile)
    return () => window.removeEventListener("resize", changeMobile)
  }, [])

  return (
    <>
      {isMobile ? (
        <>
          <Header logoOnly={true} />
          {!menuOpen && (
            <>
              <ContactUsContext />
            </>
          )}
        </>
      ) : (
        <>
          <Header logoOnly={true} />
          <ContactUsContext />
        </>
      )}
    </>
  )
}

export default ContactUsPage
