import React from "react"
import styled from "styled-components"
import { v4 as uuidv4 } from "uuid"
import {
  SectionWrapper,
  SectionWrapper2,
  InsideSectionWrapper,
  SayWrapper,
  SayingWrapper,
  DetailedSayingWrapper,
  ActionSecond,
  ActionSecond2,
  SubActWrapper,
  CardImageWrapper,
  CardTextWrapper,
  CardHeaderWrapper2,
  CardSubtextWrapper,
  ContentWrapperLink,
} from "../../building-blocks/atoms/containers/WhatWeDoContainer"
import { CardImg } from "../../building-blocks/atoms/images/images"
import {
  ActionLink,
  ActionLinks,
  CardHeaderText2,
  CardNumberText2,
  CardSubText2,
  SayingText,
  SubActionLinks,
} from "../../building-blocks/atoms/texts/WhatWeDoText"
import ScrollImg1 from "../../assets/images/scroll1.jpg"
import ScrollImg2 from "../../assets/images/scroll2.jpg"
import ScrollImg3 from "../../assets/images/scroll3.jpg"
import ScrollImg4 from "../../assets/images/scroll4.jpg"
import ScrollImg5 from "../../assets/images/scroll5.jpg"
import ScrollImg6 from "../../assets/images/scroll6.jpg"
import RightWhite from "../../assets/icons/right_white.png"

const LIST = [
  {
    id: uuidv4(),
    step: "1 / 6",
    img: ScrollImg1,
    title: "Digitization",
    context:
      "One of the most exciting evolutions revolves around going digital.",
  },
  {
    id: uuidv4(),
    step: "2 / 6",
    img: ScrollImg2,
    title: "Offline brand solutions",
    context:
      "We can help in offline branding that can attract a huge number of customers",
  },
  {
    id: uuidv4(),
    step: "3 / 6",
    img: ScrollImg3,
    title: "Marketing and promotions",
    context:
      "Best designers, production & digital marketing team to help you in  marketing fields.",
  },
  {
    id: uuidv4(),
    step: "4 / 6",
    img: ScrollImg4,
    title: "Sales",
    context:
      "individual e-commerce website & a market place for retailing experience.",
  },
  {
    id: uuidv4(),
    step: "5 / 6",
    img: ScrollImg5,
    title: "Logistics",
    context: "Fulfillment for the better reachability",
  },
  {
    id: uuidv4(),
    step: "6 / 6",
    img: ScrollImg6,
    title: "Security",
    context: "Security, authenticity and reliability are what we believe in.",
  },
]

const WhatWeDo = () => {
  const [isMobile, setisMobile] = React.useState(null)
  const [isIpad, setisIpad] = React.useState(null)


  const changeIpad = () => {
    window.matchMedia("(max-width: 1024px)").matches
      ? setisIpad(true)
      : setisIpad(false)
  }

  const changeMobile = () => {
    window.matchMedia("(max-width: 37.5em)").matches
      ? setisMobile(true)
      : setisMobile(false)
  }

  React.useEffect(() => {
    changeMobile()
    changeIpad()
    window.addEventListener("resize", changeMobile)
    return () => window.removeEventListener("resize", changeMobile)
  }, [])

  const parseStep = (steps) => {
    steps = steps.split("/")
    steps = (
      <span>
        <span style={{ color: "white" }}>{steps[0]}</span>/{steps[1]}
      </span>
    )
    return steps
  }

  return (
    <>
      <SectionWrapper data-scroll-section style={{ zIndex: 2 }}>
        <InsideSectionWrapper>
          <SayWrapper>
            <ActionLinks>What we do</ActionLinks>
          </SayWrapper>
          <SayingWrapper data-scroll data-scroll-speed="1">
            <SayingText>
              Adapt a digital mindset to enhance your business skill
            </SayingText>
          </SayingWrapper>
          <DetailedSayingWrapper data-scroll data-scroll-speed="1">
            <SubActionLinks>
            We are a company that provides a broad spectrum in every possible industry. We are here to handhold you by involving our innovative thinking and knowledge of expertise which aims high at providing efficient business solutions and services that meet the standard of global market and objectives of our clients. Honeysys helps in transforming the world’s most important business into a robust, agile organization that anticipates.
            </SubActionLinks>
          </DetailedSayingWrapper>

          {isMobile ? (
            <>
              <ActionSecond
                style={{
                  justifyContent: "flex-start",
                  padding: "0%",
                  marginTop: "0rem",
                }}
              >
                <SubActWrapper
                  data-scroll
                  data-scroll-speed="2"
                  style={{
                    width: "100%",
                    justifyContent: "flex-start",
                  }}
                >
                  <ActionLink
                    to="/what-we-do"
                    style={{
                      alignItems: "center",
                      justifyContent: "flex-start",
                      display: "flex",
                      width: "100%",
                    }}
                  >
                    explore more{" "}
                    <img
                      src={RightWhite}
                      style={{
                        marginLeft: "0.5rem",
                        width: "7%",
                        height: "auto",
                        objectFit: "cover",
                      }}
                      alt=""
                    />
                  </ActionLink>
                </SubActWrapper>
              </ActionSecond>
            </>
          ) : (
            <>
              <ActionSecond2>
                <SubActWrapper data-scroll data-scroll-speed="2">
                  <div
                    style={{
                      background: "#ACE200",
                      height: "1px",
                      width: "3.5rem",
                      marginRight: "5px",
                    }}
                  ></div>
                  <ActionLink to="/what-we-do">explore more</ActionLink>
                </SubActWrapper>
              </ActionSecond2>
            </>
          )}
        </InsideSectionWrapper>
      </SectionWrapper>

      {isMobile ? (
        <></>
      ) : (
        <>
          <SectionWrapper2 data-scroll-section>
            <HorizontalScrollableSectionWrapper2 style={{}}>
              {LIST.map((item) => (
                <ContentWrapperLink
                  data-scroll
                  data-scroll-speed="3"
                  data-scroll-direction="horizontal"
                  to={item.title.replace(/\s+/g, "-").toLowerCase()}
                >
                  <CardImageWrapper>
                    <CardImg src={item.img} alt="" />
                  </CardImageWrapper>
                  <CardTextWrapper>
                    <CardHeaderWrapper2>
                      <CardNumberText2>{parseStep(item.step)}</CardNumberText2>
                      <CardHeaderText2>{item.title}</CardHeaderText2>
                    </CardHeaderWrapper2>
                    <CardSubtextWrapper>
                      <CardSubText2>{item.context}</CardSubText2>
                    </CardSubtextWrapper>
                  </CardTextWrapper>
                </ContentWrapperLink>
              ))}
            </HorizontalScrollableSectionWrapper2>
          </SectionWrapper2>
        </>
      )}
    </>
  )
}

export default WhatWeDo


const HorizontalScrollableSectionWrapper2 = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(75, calc(100% - 36%));
  column-gap: 22%;
  padding-left: 50rem;
  overflow-x: auto;
  overflow-y: hidden;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;

  ::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }
  
  @media ${({ theme }) => theme.mediaQueries.mobile_landscape} {
  margin-bottom: 4rem;
  cursor: grab;
  padding-left: 18%;
  column-gap: 8%;
  grid-template-columns: repeat(75,calc(100% - 68%));
 
  }
  @media ${({ theme }) => theme.mediaQueries.tablet}{
    grid-template-columns: repeat(75,calc(135%));
    padding-left: 20rem;
  }
  @media ${({ theme }) => theme.mediaQueries.tablet_landscape}{
    grid-template-columns: repeat(75,calc(135%));
    padding-left: 35rem;

  }

  @media ${({ theme }) => theme.mediaQueries.ipad_pro}{
    grid-template-columns: repeat(75,calc(135%));
    column-gap: 43%;
    padding-left: 30rem;
  }

`