import { Link } from "react-router-dom"
import styled from "styled-components"

export const LayoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: var(--color-comp);
`

export const BannerWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: flex-start;
  background-color: var(--color-blk);
`

export const SectionWrapper = styled.div`
  color: white;
  display: flex;
  width: 100%;
  height: 120vh;
  align-items: center;
  justify-content: center;
  background-color: var(--color-comp);

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    height: 100vh;
  }

  @media ${({ theme }) => theme.mediaQueries.mobile_landscape} {
    height: 120vh;
  }
  
  @media ${({ theme }) => theme.mediaQueries.ipad_pro}{
    height: 80vh;
  }

`

export const SectionWrapperImg = styled.div`
  color: white;
  display: flex;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
  opacity: 0.5;
  min-height: 100%;
  background-repeat: no-repeat;
  /* background-attachment: fixed; */
  background-position: center;
  background-size: cover;
`

export const HorizontalScrollableSectionWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(75, calc(100% - 36%));
  column-gap: 9%;
  padding-left: 35rem;
  overflow-x: auto;
  overflow-y: hidden;
  scroll-behavior: smooth;

  ::-webkit-scrollbar {
    width: 0px;
    background: transparent;
`

export const InsideSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: flex-start;
  padding: 9% 12% 2% 12%;
  background-color: transparent;
  align-items: flex-start;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    padding: 30% 10%;
  }

  @media ${({ theme }) => theme.mediaQueries.mobile_landscape} {
    padding: 10% 18% 6% 18%;
  }
`

export const BannerOverlayWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
`

export const BannerOverlayTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 32%;
  height: 60vh;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0% 12%;
  margin-top: 6rem;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    font-size: 1.8rem;
    font-weight: 800;
    width: 100%;
    line-height: 2.5rem;
    margin-top: 3rem;
    margin-bottom: 5rem;
  }
`
export const ActionFirst = styled.div`
  display: flex;
  width: 100%;
  height: 30vh;
  align-items: center;
  justify-content: space-between;
  padding: 0% 12%;
`

export const ActionSecond = styled.div`
  display: flex;
  width: 100%;
  height: 15vh;
  align-items: center;
  justify-content: center;
  padding: 0% 12%;
`

export const PrevNextWrapper = styled.div`
  display: flex;
  width: auto;
  align-items: center;
  justify-content: center;
`

export const SubActWrapper = styled.div`
  display: flex;
  width: auto;
  align-items: center;
  justify-content: center;
`

export const SayWrapper = styled.div`
  display: flex;
  width: 100%;
  height: auto;
  align-items: center;
  justify-content: flex-start;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    display: flex;
    width: 100%;
    height: auto;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
`

export const SayingWrapper = styled.div`
  display: flex;
  width: 100%;
  height: auto;
  align-items: center;
  justify-content: flex-start;
  margin: 6rem 0rem;
  width: 100%;
`
export const DetailedSayingWrapper = styled.div`
  display: flex;
  width: 100%;
  height: auto;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 2rem;
  margin-top: 1rem;
  flex-wrap: wrap;
  padding: 0% 30%;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    width: 100%;
    padding: 0%;
  }

  @media ${({ theme }) => theme.mediaQueries.mobile_landscape} {
    margin: 0%;
    width: 100%;
    padding: 0%;
  }

  @media ${({ theme }) => theme.mediaQueries.ipad_pro}{
    padding: 10% 25%;
  }
  @media ${({ theme }) => theme.mediaQueries.tablet}{
    padding: 5% 10%;
  }
  @media ${({ theme }) => theme.mediaQueries.tablet_landscape}{
    padding: 0% 20%;
  }
`

export const ContentWrapper = styled.div`
  width: 100%;
  height: auto;
  display: block;
`

export const ContentWrapperLink = styled(Link)`
  width: 100%;
  height: auto;
  display: block;
  text-decoration: none;
`

export const CardImageWrapper = styled.div`
  width: 100%;
  height: auto;
`

export const CardTextWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  height: auto;
  justify-content: center;
  align-items: flex-start;
  margin: 6rem 0rem 8rem 0rem;
`

export const CardHeaderWrapper = styled.div`
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  display: flex;
  margin-bottom: 3rem;
`

export const CardSubtextWrapper = styled.div`
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  display: flex;
`

export const ServicesWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: inherit;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 6% 12%;
`
///////////////////////////////////

export const TitleBg = styled.div`
  background: var(--color-sec);
  width: 4%;
  height: 1.2rem;
  position: relative;
  top: 20%;
  left: -12%;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    display: none;
  }

  @media ${({ theme }) => theme.mediaQueries.mobile_landscape} {
    display: none;
  }
`
