import React from "react"
import {
  SectionWrapper,
  SayWrapper,
  DetailedSayingWrapper,
  SubActWrapper,
  ServicesWrapper,
  TitleBg,
} from "../../building-blocks/atoms/containers/OurClientsContainer"
import {
  ActionLinks,
  BannerOverlayText,
  SubActionLinks,
} from "../../building-blocks/atoms/texts/OurClientsText"
import RightBlack from "../../assets/icons/right_black.png"

const OurClients = () => {
  const [isMobile, setisMobile] = React.useState(null)

  const changeMobile = () => {
    window.matchMedia("(max-width: 37.5em)").matches
      ? setisMobile(true)
      : setisMobile(false)
  }

  React.useEffect(() => {
    changeMobile()
    window.addEventListener("resize", changeMobile)
    return () => window.removeEventListener("resize", changeMobile)
  }, [])

  return (
    <SectionWrapper data-scroll-section>
      <ServicesWrapper>
        <SayWrapper>
          <ActionLinks style={{ color: "black" }}>our clients</ActionLinks>
          <TitleBg />
        </SayWrapper>
        <>
          <BannerOverlayText data-scroll data-scroll-speed="1">
            We are partnered with the best! Our client list speaks for itself..
          </BannerOverlayText>
        </>

        <DetailedSayingWrapper data-scroll data-scroll-speed="1">
          <SubActionLinks>
            We're all about helping our clients reach their ecommerce goals and
            bring about the best possible solution to create a branded platform
            for them. We follow the mantra ‘Lets unite together to create
            success stories’
          </SubActionLinks>
        </DetailedSayingWrapper>

        {isMobile ? (
          <>
            <SubActWrapper
              data-scroll
              data-scroll-speed="2"
              style={{
                width: "100%",
                justifyContent: "flex-start",
                margin: "0%",
                marginTop: "2rem",
              }}
            >
              <ActionLinks
                style={{
                  alignItems: "center",
                  justifyContent: "flex-start",
                  display: "flex",
                  width: "100%",
                  color: "black",
                  fontWeight: 600,
                }}
              >
                explore more{" "}
                <img
                  src={RightBlack}
                  style={{
                    marginLeft: "0.5rem",
                    width: "7%",
                    height: "auto",
                    objectFit: "cover",
                  }}
                  alt=""
                />
              </ActionLinks>
            </SubActWrapper>
          </>
        ) : (
          <>
            {/* <SubActWrapper data-scroll data-scroll-speed="2">
              <ActionLinks style={{ color: "black" }}>
                know about our clients
              </ActionLinks>
            </SubActWrapper> */}
          </>
        )}
      </ServicesWrapper>
    </SectionWrapper>
  )
}

export default OurClients
