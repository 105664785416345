const theme = {
  colors: {
    pri: "#ACE200",
    sec: "#2680EB",
    comp: "#12111F",
    wht: "#FFFFFF",
    blk: "#1D2120",
    strk: "#64D1F9",
  },

  size: {
    smallest: "25em", //275px #
    smaller: "31.25em", //500px
    small: "37.5em", //600px #
    medium: "56.25em", //900px #
    large: "80em", //1300px
    larger: "90em", //1300px
    largest: "97em", //1500px #
    extra_large: "160em", //4k
  },

  mediaQueries: {
    mobile:
      "only screen and ( min-device-width: 319px) and (max-device-width: 1022px) and (orientation:portrait)",

    mobile_landscape:
      "only screen and ( min-device-width: 319px) and (max-device-width: 1022px) and (orientation:landscape)",

    tablet:
      "only screen and (min-device-width: 760px) and (max-device-width: 810px) and (orientation: portrait)",

    tablet_landscape:
      "only screen and (min-device-width: 813px) and (max-device-width: 1024px) and (orientation: landscape)",

    ipad_pro:
    "only screen and (min-device-width: 1023px) and (max-device-width: 1029px) and (orientation: portrait)",
  
    

    laptop:
      "only screen and (min-device-width: 1030px) and (orientation: landscape)",

    desktop:
      "only screen and (min-device-width: 1280px) and (orientation:landscape)",

    wide_desktop: "screen and (min-device-width: 2000px)",
  },
}

export default theme
