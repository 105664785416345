import LocomotiveScroll from "locomotive-scroll"

export const scroll = () =>
  new LocomotiveScroll({
    el: document.querySelector("[data-scroll-container]"),
    smooth: true,
    multiplier: 0.45,
    mobile: {
      smooth: true,
    },
    tablet: {
      smooth: true,
    },
  })
