import React from "react"
import { Link } from "react-router-dom"
import { useTransition, config, animated } from "react-spring"
import styled from "styled-components"
import Menu1 from "../../assets/images/menu1.png"

const LINKS = [
  {
    id: 1,
    link: "home",
    url: "/",
  },
  {
    id: 2,
    link: "about us",
    url: "/about-us",
  },
  {
    id: 3,
    link: "portfolio",
    url: "/portfolio",
  },
  {
    id: 4,
    link: "platform",
    url: "/platform",
  },
  {
    id: 5,
    link: "potential",
    url: "/potential",
  },
  {
    id: 6,
    link: "prospect",
    url: "/prospect",
  },
  {
    id: 7,
    link: "career",
    url: "/work-with-us",
  },
]

const SUB_LINKS = [
  {
    id: 1,
    link: "brands",
    url: "/",
  },
  {
    id: 2,
    link: "partners",
    url: "/",
  },
  {
    id: 3,
    link: "careers",
    url: "/work-with-us",
  },
  {
    id: 4,
    link: "inquiries",
    url: "/contact-us",
  },
  {
    id: 5,
    link: "contact us",
    url: "/contact-us",
  },
  {
    id: 6,
    link: "transform",
    url: "/",
  },
]

export const MenuTextLink = styled(Link)`
  color: white;
  font-family: roboto;
  font-size: 1.68rem;
  letter-spacing: 0.1rem;
  font-weight: 300;
  width: 100%;
  margin-top: 1.5rem;
  text-decoration: none;
  text-transform: capitalize;
  transition: all var(--speed);

  &:hover {
    color: var(--color-pri);
  }

  &:focus {
    color: var(--color-pri);
  }

  &:active {
    color: var(--color-pri);
  }

  @media ${({ theme }) => theme.mediaQueries.mobile_landscape} {
    color: white;
    font-family: roboto;
    font-size: 1.2rem;
    -webkit-letter-spacing: 0.1rem;
    -moz-letter-spacing: 0.1rem;
    -ms-letter-spacing: 0.1rem;
    letter-spacing: 0.1rem;
    font-weight: 300;
    width: 45%;
    margin-top: 7%;
    -webkit-text-decoration: none;
    text-decoration: none;
    text-transform: capitalize;
    -webkit-transition: all var(--speed);
    transition: all var(--speed);
  }
`

export const ActionLinks = styled.p`
  color: white;
  font-size: 0.8rem;
  font-family: "Roboto";
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0.1rem;
  transition: all var(--speed);
  font-size: 0.7rem;
  font-weight: 400;
  cursor: pointer;

  &:hover {
    color: var(--color-pri);
  }

  &:focus {
    color: var(--color-pri);
  }

  &:active {
    color: var(--color-pri);
  }

  @media ${({ theme }) => theme.mediaQueries.mobile_landscape} {
    color: white;
    font-family: "Roboto";
    text-transform: uppercase;
    -webkit-letter-spacing: 0.1rem;
    -moz-letter-spacing: 0.1rem;
    -ms-letter-spacing: 0.1rem;
    -webkit-letter-spacing: 0.1rem;
    -moz-letter-spacing: 0.1rem;
    -ms-letter-spacing: 0.1rem;
    letter-spacing: 0.1rem;
    margin-right: 0rem;
    font-size: 0.65rem;
    font-weight: 400;

    position: absolute;
    top: 16%;
    right: 7%;
  }
`

export const InsideSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: flex-start;
  padding: 6% 18%;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    height: auto;
    padding: 25% 12% 30% 12%;
    align-items: flex-start;
  }

  @media ${({ theme }) => theme.mediaQueries.mobile_landscape} {
    width: 90%;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
  }
`

export const SectionWrapper = styled(animated.div)`
  color: white;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  background: var(--color-comp);

  flex-direction: row;
  z-index: 12;
  position: absolute;
  top: 0;
  left: 0;
  max-width: 100vw;
  max-height: 100vh;
  padding: 0%,

  transition: all var(--speed);
  position: fixed;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    height: auto;
    max-height: fit-content;
    position: unset;
  }

  
  @media ${({ theme }) => theme.mediaQueries.mobile_landscape} {
  justify-content: flex-start;}
`

const LocationText = styled.p`
  color: white;
  font-size: 0.7rem;
  font-family: "Graphik";
  text-transform: uppercase;
  font-weight: 400;
  letter-spacing: 0.4rem;
`

const AddressContactText = styled.a`
  text-decoration: none;
  color: white;
  font-size: 0.65rem;
  font-family: "Roboto";
  font-weight: 400;
  letter-spacing: 0rem;
  opacity: 0.5;
  line-height: 1.2rem;
`

const SubLinksWrapper = styled.div`
  display: flex;
  flex-flow: column wrap;
  width: 78%;
  height: 15%;
  margin: 5% 0%;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    margin: 0%;
    height: auto;
    margin-top: 20%;
  }

  @media ${({ theme }) => theme.mediaQueries.mobile_landscape} {
    flex-flow: column;
    flex-direction: column;
    margin: 4% 0%;
    height: auto;
    width: auto;
  }
`

const SubLink = styled(Link)`
  color: white;
  font-size: 0.65rem;
  font-family: "Roboto";
  font-weight: 400;
  letter-spacing: 0rem;
  opacity: 0.5;
  line-height: 1.4rem;
  text-transform: capitalize;
  transition: all var(--speed);
  text-decoration: none;
  line-height: 1.9rem;

  &:hover {
    opacity: 1;
  }

  &:focus {
    color: var(--color-pri);
    opacity: 1;
  }

  &:active {
    color: var(--color-pri);
    opacity: 1;
  }

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    font-size: 0.8rem;
    letter-spacing: 0.1rem;
    line-height: 2.5rem;
  }

  @media ${({ theme }) => theme.mediaQueries.mobile_landscape} {
    color: white;
    font-size: 0.7rem;
    font-family: "Roboto";
    font-weight: 400;
    -webkit-letter-spacing: 0rem;
    -moz-letter-spacing: 0rem;
    -ms-letter-spacing: 0rem;
    letter-spacing: 0rem;
    opacity: 0.5;
    line-height: 1.4rem;
    text-transform: capitalize;
    -webkit-transition: all var(--speed);
    transition: all var(--speed);
    -webkit-text-decoration: none;
    text-decoration: none;
    line-height: 1.9rem;
  }
`

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: self-start;
  width: 100%;
  height: 87%;
`

const MenuPage = ({ setMenuOpen, menuOpen }) => {
  const SideDrawerTransition = useTransition(menuOpen, null, {
    config: config.stiff,
    from: { opacity: 0, transform: "translateX(50%)" },
    enter: { opacity: 1, transform: "translateX(0%)" },
    leave: { opacity: 0, transform: "translateX(50%)" },
  })

  const [isMobile, setisMobile] = React.useState(null)

  const changeMobile = () => {
    window.matchMedia("(max-width: 37.5em)").matches
      ? setisMobile(true)
      : setisMobile(false)
  }

  React.useEffect(() => {
    changeMobile()
    window.addEventListener("resize", changeMobile)
    return () => window.removeEventListener("resize", changeMobile)
  }, [])

  return (
    <>
      {SideDrawerTransition.map(
        ({ item, key, props }) =>
          item && (
            <SectionWrapper key={key} style={props}>
              {isMobile ? (
                <InsideSectionWrapper>
                  {LINKS.map((i) => (
                    <MenuTextLink to={i.url} key={i.id}>
                      {i.link}
                    </MenuTextLink>
                  ))}
                  <SubLinksWrapper>
                    {SUB_LINKS.map((i) => (
                      <SubLink to={i.url} key={i.id}>
                        {i.link}
                      </SubLink>
                    ))}
                  </SubLinksWrapper>
                </InsideSectionWrapper>
              ) : window.screen.orientation.type === "landscape-primary" &&
                window.screen.width < 1024 ? (
                <>
                  <InsideSectionWrapper>
                    {LINKS.map((i) => (
                      <MenuTextLink to={i.url} key={i.id}>
                        {i.link}
                      </MenuTextLink>
                    ))}
                    <SubLinksWrapper>
                      {SUB_LINKS.map((i) => (
                        <SubLink to={i.url} key={i.id}>
                          {i.link}
                        </SubLink>
                      ))}
                    </SubLinksWrapper>
                  </InsideSectionWrapper>
                  <ActionLinks onClick={() => setMenuOpen(!menuOpen)}>
                    close
                  </ActionLinks>
                </>
              ) : (
                <>
                  <InsideSectionWrapper
                    style={{
                      padding: "0%",
                      width: "35%",
                      alignItems: "center",
                      justifyContent: "center"
                    }}
                  >
                    <img
                      src={Menu1}
                      alt=""
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                    />
                  </InsideSectionWrapper>
                  <InsideSectionWrapper
                    style={{
                      padding: "9%",
                      width: "28%",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {LINKS.map((i) => (
                      <MenuTextLink to={i.url} key={i.id}>
                        {i.link}
                      </MenuTextLink>
                    ))}
                  </InsideSectionWrapper>
                  <InsideSectionWrapper
                    style={{
                      width: "35%",
                      alignItems: "center",
                      justifyContent: "space-between",
                      borderLeft: "1px solid rgba(255,255,255, 0.1)",
                      fontFamily: "graphik",
                      flexDirection: "row",
                      padding: "0rem 3rem 0rem 6rem",
                      overflowY: "scroll"
                    }}
                  >
                    <InfoWrapper>
                      <div
                        style={{
                          marginBottom: "5%",
                        }}
                      >
                        <LocationText>IND</LocationText>
                        <AddressContactText>
                          HONEYSYS IT SERVICES PRIVATE LIMITED
                          <br />
                          #231, 13th Cross Road,
                          <br />
                          Indiranagar 2nd Stage, Bengaluru - 560038
                          <br />
                          INDIA.
                          <br />
                          +91 80 4215 6999
                        </AddressContactText>
                      </div>
                      <div
                        style={{
                          marginBottom: "5%",
                        }}
                      >
                        <LocationText>USA</LocationText>
                        <AddressContactText>
                          HONEYSYS LLC
                          <br />
                          9980 S 300 W, <br />
                          Suite 200, Sandy UT 84070, Utah
                          <br />
                          USA
                          <br />
                          +1 (385) 275 2777
                          <br />
                          <br />
                          HONEYSYS LLC
                          <br />
                          250 Monroe Ave NW
                          <br />
                          Suite 400, Grand Rapids MI 49503, Michigan
                          <br />
                          USA
                          <br />
                          +1 (385) 275 2777
                        </AddressContactText>
                      </div>
                      <div
                        style={{
                          marginBottom: "5%",
                        }}
                      >
                        <LocationText>UK</LocationText>
                        <AddressContactText>
                          HONEYSYS LTD
                          <br />
                          71-75, Shelton Street, London,
                          <br />
                          England, WC2H 9JQ
                          <br />
                          UNITED KINGDOM
                          <br />
                          +44 20 3289 9990
                        </AddressContactText>
                      </div>
                      <div>
                        <LocationText>UAE</LocationText>
                        <AddressContactText>
                          HONEYSYS FZE
                          <br />
                          DTEC Dubai Technology Entrepreneur Campus
                          <br />
                          Dubai Silicon Oasis, Dubai
                          <br />
                          UAE.
                          <br />
                          +971 4 326 9186
                        </AddressContactText>
                      </div>
                      <SubLinksWrapper>
                        {SUB_LINKS.map((i) => (
                          <SubLink to={i.url} key={i.id}>
                            {i.link}
                          </SubLink>
                        ))}
                      </SubLinksWrapper>
                      <div>
                        <AddressContactText
                          style={{
                            fontSize: "0.82rem",
                            opacity: "0.9",
                          }}
                        >
                          info@honeysys.com
                        </AddressContactText>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          width: "65%",
                        }}
                      >
                        <AddressContactText
                          target="_blank"
                          href="https://www.facebook.com/Honeysys-1525727621034411/about/"
                          style={{
                            fontSize: "0.82rem",
                            opacity: "0.9",

                          }}
                        >
                          Fb
                        </AddressContactText>
                        <AddressContactText
                          target="_blank"
                          style={{
                            fontSize: "0.82rem",
                            opacity: "0.9",
                          }}
                        >
                          Ig
                        </AddressContactText>
                        <AddressContactText
                          target="_blank"
                          style={{
                            fontSize: "0.82rem",
                            opacity: "0.9",
                          }}
                        >
                          Tw
                        </AddressContactText>
                        <AddressContactText
                          target="_blank"
                          href="https://www.linkedin.com/company/honeysys/?originalSubdomain=in"
                          style={{
                            fontSize: "0.82rem",
                            opacity: "0.9",
                          }}
                        >
                          In
                        </AddressContactText>
                        <AddressContactText
                          target="_blank"
                          style={{
                            fontSize: "0.82rem",
                            opacity: "0.9",
                          }}
                        >
                          Be.
                        </AddressContactText>
                        <AddressContactText
                          target="_blank"
                          href=" https://honeysys.com/terms-of-use"
                          style={{
                            fontSize: "0.82rem",
                            opacity: "0.9",
                          }}
                        >
                          T&C
                        </AddressContactText>
                      </div>
                    </InfoWrapper>
                    <ActionLinks
                      style={{
                        fontSize: "0.7rem",
                        fontWeight: "400",
                        cursor: "pointer",
                      }}
                      onClick={() => setMenuOpen(!menuOpen)}
                    >
                      close
                    </ActionLinks>
                  </InsideSectionWrapper>
                </>
              )}
            </SectionWrapper>
          )
      )}
    </>
  )
}

export default MenuPage
