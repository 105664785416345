import styled from "styled-components"

export const BannerImg = styled.img`
  width: 94%;
  margin-right: -0.2rem;
  object-fit: cover;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    height: 75%;
    width: 100%;
    margin-top: -18%;
    margin-right: 0%;
  }
`

export const CardImg = styled.img`
  width: 100%;
  height: 75vh;
  object-fit: cover;
  @media ${({ theme }) => theme.mediaQueries.tablet}{
    width: 65vh;
    height: 55vh;
  }
  @media ${({ theme }) => theme.mediaQueries.ipad_pro}{
    width: 65vh;
  height: 55vh;
  width: 120%;
  }
  @media ${({ theme }) => theme.mediaQueries.tablet_landscap}{
  width: 120%;
  }

`

export const BackImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

export const ServicesBackImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`
