import PropTypes from "prop-types"
import { LayoutContainer } from "../../building-blocks/atoms/containers/containers"

const Layout = ({ children }) => {
  return (
    <LayoutContainer
      // id="c-fixed_wrapper"
      data-scroll-container
    >
      {children}
    </LayoutContainer>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
