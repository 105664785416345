import React from "react"
import styled from "styled-components"
import platform1 from "../../assets/images/platform1.jpg"
import platform11 from "../../assets/images/platform1-1.jpg"
import krazylogo from "../../assets/images/krazylogo.png"
import krazy1 from "../../assets/images/krazy1.png"
import { scroll } from "../../utils/locomotiveScroll"
// import case1 from "../../assets/images/case2-1 (1).png"
// import case2 from "../../assets/images/case2-1 (2).png"
// import case3 from "../../assets/images/case2-1 (3).png"
// import case4 from "../../assets/images/case2-1 (4).png"
// import case5 from "../../assets/images/case2-1 (5).png"
// import case6 from "../../assets/images/case2-1 (6).png"

// import case11 from "../../assets/images/case3-1.png"
import { AddressContactText } from "../../building-blocks/atoms/containers/FooterContainer"

// const LIST_IMAGE = [
//   {
//     src: case1,
//   },
//   {
//     src: case2,
//   },
//   {
//     src: case3,
//   },
//   {
//     src: case4,
//   },
//   {
//     src: case5,
//   },
//   {
//     src: case6,
//   },
// ]

// const LIST_IMAGE2 = [
//   {
//     src: case11,
//   },
// ]

export const BannerWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: flex-start;
  background-color: var(--color-blk);
`

export const BannerOverlayWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  background: linear-gradient(to left, rgba(255, 235, 125, 0.1), #f8d411);

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    justify-content: flex-start;
    padding: 40% 0%;
  }
`

export const BannerOverlayTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 38vh;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0% 12%;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    justify-content: center;
    height: 30vh;
  }
`
export const ActionFirst = styled.div`
  display: flex;
  width: 100%;
  height: 30vh;
  align-items: center;
  justify-content: space-between;
  padding: 0% 12%;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    font-weight: 400;
    font-family: roboto;
    font-size: 1.5rem;
    width: 100%;
    line-height: 2.4rem;
    margin: 0%;
  }
`

export const ActionSecond = styled.div`
  display: flex;
  width: 100%;
  height: 15vh;
  align-items: center;
  justify-content: center;
  padding: 0% 12%;
`

export const PrevNextWrapper = styled.div`
  display: flex;
  width: auto;
  align-items: center;
  justify-content: center;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    padding: 0% 12%;
  }
`

export const SubActWrapper = styled.div`
  display: flex;
  width: auto;
  align-items: center;
  justify-content: center;
`

export const TitleBg = styled.div`
  background: var(--color-sec);
  width: 6%;
  height: 1.2rem;
  position: relative;
  top: -3.5%;
  left: -1.5%;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    display: none;
  }
`

///////////////////////////////////////////////

export const BannerOverlaySubtext = styled.p`
  color: white;
  font-family: roboto;
  font-size: 0.8rem;
  text-transform: capitalize;
  letter-spacing: 0.3rem;
  font-weight: 600;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    z-index: 1;
    font-size: 0.7rem;
    letter-spacing: 0.2rem;
    opacity: 0.8;
  }
`

export const BannerOverlayText = styled.h1`
  color: white;
  font-family: graphik;
  font-size: 3.2rem;
  letter-spacing: 0.1rem;
  font-weight: 700;
  width: 40%;
  margin-top: 2rem;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    font-weight: 800;
    font-size: 2rem;
    width: 100%;
    line-height: 3.5rem;
    margin-top: 2rem;
  }
`

export const BannerOverlayText2 = styled.h1`
  width: 100%;
  font-weight: 300;
  font-family: roboto;
  font-size: 3.2rem;
  color: black;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    font-weight: 400;
    font-family: roboto;
    font-size: 1.2rem;
    width: 100%;
    line-height: 2.4rem;
    margin: 0%;
    letter-spacing: 0.08rem;
  }
`

export const ActionLinks = styled.p`
  color: white;
  font-size: 0.8rem;
  font-family: "Roboto";
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0.1rem;
`

export const ActionLinks2 = styled.p`
  color: white;
  font-family: "Roboto";
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  margin-right: ${(props) => (props.left ? "7rem" : "0rem")};
  font-size: 0.7rem;
  font-weight: 400;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    text-transform: none;
    font-size: 0.8rem;
    font-weight: 400;
    line-height: 2;
    width: 92%;
    letter-spacing: 0.08rem;
  }
`

////////////////////////////////////////////

export const BannerImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    height: 100%;
    width: 100%;
    margin-top: 0%;
    object-fit: cover;
    margin-right: 0%;
  }
`

/////////////////////////////////////////////////

export const SectionWrapper1 = styled.div`
  color: white;
  display: flex;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
  background-color: var(--color-comp);
  height: 90vh;
  z-index: 2;
`

export const SectionWrapper2 = styled.div`
  color: white;
  display: flex;
  width: 100%;
  height: 120vh;
  align-items: center;
  justify-content: center;
  background-color: var(--color-comp);

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    height: 100vh;
  }
`

export const BackImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    height: 100%;
    width: auto;
  }
`

export const InsideSectionWrapper1 = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 15% 18% 0% 15%;
`

export const BannerOverlayText1 = styled.h1`
  color: white;
  font-family: graphik;
  font-size: 3.5rem;
  letter-spacing: 0.1rem;
  font-weight: 700;
  width: 90%;
  margin-top: 6rem;
`

export const BannerOverlaySubtext1 = styled.p`
  color: white;
  font-family: roboto;
  font-size: 0.8rem;
  text-transform: uppercase;
  letter-spacing: 0.3rem;
  font-weight: 600;
  padding: 0% 3%;
`

export const SectionWrapper3 = styled.div`
  color: white;
  display: flex;
  width: 100%;
  height: auto;
  align-items: center;
  justify-content: center;
  background-color: var(--color-comp);
  height: 120vh;
  z-index: 2;
  position: relative;

  padding: 12% 20%;
  background-color: rgb(225, 230, 236);
  justify-content: space-between;
  align-items: center;
  flex-direction: row;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    padding: 30% 12%;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column-reverse;
  }
`

export const ServicesWrapper1 = styled.div`
  width: 100%;
  height: inherit;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 6% 30% 14%;
  background-color: var(--color-wht);
  position: absolute;

  padding: 0;
  width: 52%;
  position: unset;
  background-color: transparent;
  justify-content: center;
  align-items: flex-start;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    width: 100%;
    height: auto;
    margin-bottom: 12vh;
  }
`

export const BannerOverlayText3 = styled.h1`
  color: white;
  font-family: roboto;
  font-size: 3.2rem;
  letter-spacing: 0.1rem;
  font-weight: 300;
  margin-top: 5rem;
  width: 100%;
  color: black;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    font-weight: 400;
    font-family: roboto;
    font-size: 1.2rem;
    width: 60%;
    line-height: 2.4rem;
    margin: 0%;
    letter-spacing: 0.08rem;
  }
`

export const ActionLinks1 = styled.p`
  color: white;
  font-family: "Roboto";
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0.1rem;

  text-transform: none;
  line-height: 2;
  font-weight: 300;
  width: 100%;
  letter-spacing: 0.02rem;
  color: black;
  margin-top: 3rem;
  font-size: 1rem;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    text-transform: none;
    font-size: 0.8rem;
    font-weight: 400;
    line-height: 2;
    font-weight: 300;
    width: 92%;
    letter-spacing: 0.08rem;
  }
`

export const ActionLinks3 = styled.p`
  font-family: "Roboto";
  text-transform: uppercase;
  font-weight: 600;
  -webkit-letter-spacing: 0.1rem;
  -moz-letter-spacing: 0.1rem;
  -ms-letter-spacing: 0.1rem;
  letter-spacing: 0.1rem;
  text-transform: none;
  line-height: 3.4;
  font-weight: 400;
  width: 100%;
  -webkit-letter-spacing: 0.02rem;
  -moz-letter-spacing: 0.02rem;
  -ms-letter-spacing: 0.02rem;
  letter-spacing: 0.02rem;
  color: black;
  margin-top: 2rem;
  margin-bottom: 7rem;
  font-size: 1rem;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    text-transform: none;
    font-size: 0.8rem;
    font-weight: 400;
    line-height: 2;
    font-weight: 300;
    width: 92%;
    letter-spacing: 0.08rem;
  }
`

export const SectionWrapper = styled.div`
  color: white;
  display: flex;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
  background-color: var(--color-comp);
`

export const SectionWrapper10 = styled.div`
  color: white;
  display: flex;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
  background-color: var(--color-comp);

  height: 100vh;
  z-index: 6;
  position: relative;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    height: 70vh;
    z-index: 6;
    position: relative;
  }
`

export const SectionWrapper9 = styled.div`
  color: white;
  display: flex;
  width: 100%;
  height: 160vh;
  align-items: center;
  justify-content: center;
  background-color: rgb(225, 230, 236);

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    align-items: flex-start;
    height: 110vh;
  }
`

export const SectionWrapper6 = styled.div`
  color: white;
  display: flex;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
  background-color: var(--color-comp);

  height: 172vh;
  z-index: 2;
  position: relative;
`

export const SectionWrapper7 = styled.div`
  color: white;
  display: flex;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
  background-color: var(--color-comp);

  height: 530vh;
  z-index: 6;
  position: relative;
`

export const SectionWrapper4 = styled.div`
  color: white;
  display: flex;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
  background-color: rgb(225, 230, 236);

  height: 204vh;
  z-index: 2;
  position: relative;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    height: 55vh;
  }
`

export const SectionWrapper5 = styled.div`
  color: white;
  display: flex;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
  background-color: rgb(225, 230, 236);

  height: 100vh;
  z-index: 2;
  position: relative;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    height: 80vh;
  }
`

export const SectionWrapper8 = styled.div`
  color: white;
  display: flex;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
  background-color: rgb(225, 230, 236);

  height: 90vh;
  z-index: 2;
  position: relative;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    height: 55vh;
  }
`

export const ServicesWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: inherit;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  background-color: rgb(225, 230, 236);
  padding: 14% 18% 14% 24%;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    padding: 30% 12%;
    justify-content: center;
  }
`

export const ServicesWrapper3 = styled.div`
  position: absolute;
  width: 100%;
  height: inherit;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  background-color: rgb(225, 230, 236);
  padding: 5% 18% 14% 24%;

  justify-content: center;
  padding: 10% 30%;
  align-items: center;
  height: auto;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    padding: 30% 12%;
    justify-content: center;
  }
`

export const ServicesWrapper2 = styled.div`
  position: absolute;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  background-color: rgb(225, 230, 236);
  padding: 0% 18% 14% 24%;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    padding: 10% 12%;
    justify-content: center;
  }
`

// const GridWrapper = styled.div`
//   display: flex;
//   width: 100%;
//   justify-content: space-between;
//   align-items: center;
//   height: auto;
//   z-index: 2;
//   flex-wrap: wrap;
//   flex-direction: column;
//   position: absolute;
//   background-color: rgb(225, 230, 236);

//   height: 100%;
//   padding: 0% 18%;
//   flex-direction: row;
//   flex-wrap: wrap;
//   align-items: center;
//   justify-content: space-evenly;

//   display: flex;
//   flex-wrap: wrap;
//   justify-content: space-around;
//   align-items: center;

//   @media ${({ theme }) => theme.mediaQueries.mobile} {
//     padding: 7%;
//   }
// `

// const Img = styled.img`
//   width: 30%;
//   padding: 2%;
//   height: auto;

//   @media ${({ theme }) => theme.mediaQueries.mobile} {
//     width: 48%;
//     padding: 4%;
//     height: auto;
//   }
// `

// const ImgWrapper = styled.div`
//   display: flex;
//   width: 100%;
//   z-index: 2;
//   position: absolute;
//   background-color: rgb(225, 230, 236);
//   height: 100%;
//   padding: 0% 10%;
//   flex-direction: column;
//   align-items: flex-start;
//   justify-content: flex-start;
//   position: absolute;

//   display: flex;
//   width: 100%;
//   z-index: 2;
//   position: absolute;
//   background-color: rgb(225, 230, 236);
//   height: 100%;
//   padding: 0% 10%;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
//   position: absolute;

//   @media ${({ theme }) => theme.mediaQueries.mobile} {
//     align-items: center;
//     justify-content: center;
//   }
// `

// const Img2 = styled.img`
//   width: 38%;
//   height: auto;
//   margin-bottom: 9%;

//   @media ${({ theme }) => theme.mediaQueries.mobile} {
//     width: 100%;
//     height: auto;
//     margin-bottom: 9%;
//   }
// `

const LogoImage = styled.img`
  width: 22%;
  height: auto;
  object-fit: cover;
  &:hover {
    background-color: #f8d53a;
  }

  &:focus {
    color: var(--color-pri);
  }

  &:active {
    color: var(--color-pri);
  }
  @media ${({ theme }) => theme.mediaQueries.mobile} {
    width: 60%;
    height: auto;
    object-fit: cover;
  }
`

function KrazyfastContext() {
  React.useEffect(() => {
    scroll()
  }, [])

  return (
    <>
      <BannerWrapper data-scroll-section>
        <BannerImg src={platform1} alt="" data-scroll data-scroll-speed="-3" />
        <BannerOverlayWrapper>
          <BannerOverlayTextWrapper data-scroll data-scroll-speed="-2">
            <BannerOverlaySubtext style={{ zIndex: "2" }}>
              Industry - Logistics
            </BannerOverlaySubtext>

            <BannerOverlayText>Krazyfast</BannerOverlayText>
          </BannerOverlayTextWrapper>
          <>
            <ActionFirst data-scroll data-scroll-speed="-1">
              <SubActWrapper>
                <ActionLinks>UI/ UX, Website, App</ActionLinks>
              </SubActWrapper>
              {/* <PrevNextWrapper>
                <ActionLinks2 left>prev</ActionLinks2>
                <ActionLinks2>next</ActionLinks2>
              </PrevNextWrapper> */}
            </ActionFirst>
            <ActionSecond
              style={{
                position: "absolute",
                bottom: "-5rem",
              }}
              data-scroll
              data-scroll-speed="-2"
            >
              <SubActWrapper>
                <ActionLinks style={{ letterSpacing: "0.28rem" }}>
                  scroll down
                </ActionLinks>
              </SubActWrapper>
            </ActionSecond>
          </>
        </BannerOverlayWrapper>
      </BannerWrapper>

      <SectionWrapper3 data-scroll-section>
        <LogoImage src={krazylogo} alt="" />
        <ServicesWrapper1>
          <>
            <BannerOverlayText3 data-scroll data-scroll-speed="1">
            A super economical solution for all your logistics & delivery services
            </BannerOverlayText3>
          </>
          <ActionLinks1 data-scroll data-scroll-speed="1">
          We are here to provide fast deliveries with our logistics application. All the products will be delivered to the customer's doorsteps with ease and peace, reducing a big hustle for the retailer. Krazy fast is an open channel that aims at converting local delivery services into a brand using its network and offers facilities like ‘on-demand delivery’ and ‘reverse logistics’ which makes it feasible for retailers to transport goods and services.
          </ActionLinks1>
          <AddressContactText target="_blank" href="https://www.kraztfast.com/" style={{color: '#333', fontWeight: 'bold'}}>
              VISIT WEBSITE &rarr;
            </AddressContactText>
        </ServicesWrapper1>
      </SectionWrapper3>

      <SectionWrapper2 data-scroll-section>
        <BackImg
          src={platform11}
          style={{ height: "130vh" }}
          alt=""
          data-scroll
          data-scroll-speed="-5"
        />
      </SectionWrapper2>

      <SectionWrapper4
        data-scroll-section
        style={{ marginBlock: "-0.5rem", zIndex: 6 }}
      >
        <ServicesWrapper3>
          <>
            <BannerOverlayText2 data- data-scroll data-scroll-speed="1">
            Faster And Safer Shipments With
            Scheduled And On-Demand Delivery
            Platform.
            <br />
            <br />
            Feasible Last Mile Connectivity And
            Reverse Logistics
            </BannerOverlayText2>
            <img alt='' src={krazy1} />

            <ActionLinks3 data-scroll data-scroll-speed="1">
            The global fulfillment services market is growing at a steady
            pace and is expected to reach a value of $55.6 billion by 2021
            <br />
            <br />
            The global e-commerce fulfillment services market size was
            valued at USD 69.68 billion in 2019 and is expected to expand
            at a compound annual growth rate (CAGR) of 6.5% from 2020
            to 2027
            </ActionLinks3>
          </>

          {/* <>
            <BannerOverlayText2 data- data-scroll data-scroll-speed="1">
              Maximize ROI with prebuilt inetgrations
            </BannerOverlayText2>

            <ActionLinks3 data-scroll data-scroll-speed="1">
              Benifit from many prebuilt integrations with SAP, Oracle,
              Microsoft, and other ERP applications
              <br />
              Easy end-to-end ecommerce processes
              <br />
              Reduce cost, risk, effort, and time with backend/ERP applications
            </ActionLinks3>
          </> */}

          {/* <>
            <BannerOverlayText2 data- data-scroll data-scroll-speed="1">
              Innovate with Microservices
            </BannerOverlayText2>

            <ActionLinks3 data-scroll data-scroll-speed="1">
              Increase operational efficiency and gain maximum flexibility
              <br />
              Develop cloud-native, side-by-side extensions through Honebi and
              APIs
            </ActionLinks3>
          </> */}
          {/* <>
            <BannerOverlayText2 data- data-scroll data-scroll-speed="1">
              Partner Implementation
            </BannerOverlayText2>

            <ActionLinks3 data-scroll data-scroll-speed="1">
              Benifit from expertise to guide your Honebi project. Our partner
              platform is built to provide services and extend support to meet
              your enterprise goals
            </ActionLinks3>
          </> */}
        </ServicesWrapper3>
      </SectionWrapper4>
    </>
  )
}

export default KrazyfastContext
