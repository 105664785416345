import styled from "styled-components"

export const BannerWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: flex-start;
  background-color: var(--color-blk);
  @media ${({ theme }) => theme.mediaQueries.tablet}{
    height: 80vh;
  }

`

export const BannerOverlayWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    align-items: flex-start;
  }

  @media ${({ theme }) => theme.mediaQueries.mobile_landscape} {
    justify-content: flex-end;
  }
`

export const BannerOverlayTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 60vh;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0% 12%;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    justify-content: center;
  }

  @media ${({ theme }) => theme.mediaQueries.mobile_landscape} {
    height: 45vh;
  }
  @media ${({ theme }) => theme.mediaQueries.ipad_pro} {
    // display: none;
    height: 30vh;
    
  }
  @media ${({ theme }) => theme.mediaQueries.tablet}{
    margin-top: 20%;
  }


`
export const ActionFirst = styled.div`
  display: flex;
  width: 100%;
  height: 30vh;
  align-items: center;
  justify-content: space-between;
  padding: 0% 12%;

  @media ${({ theme }) => theme.mediaQueries.mobile_landscape} {
    height: 10vh;
    margin-top: 8%;
    margin-bottom: 4%;
  }
`

export const ActionSecond = styled.div`
  display: flex;
  width: 100%;
  height: 15vh;
  align-items: center;
  justify-content: center;
  padding: 0% 12%;
`

export const PrevNextWrapper = styled.div`
  display: flex;
  width: auto;
  align-items: center;
  justify-content: center;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    padding: 0% 12%;
  }
`

export const SubActWrapper = styled.div`
  display: flex;
  width: auto;
  align-items: center;
  justify-content: center;

  // @media ${({ theme }) => theme.mediaQueries.large} {
  //   margin-left: 5rem;
  // }

  @media ${({ theme }) => theme.mediaQueries.mobile_landscape} {
    margin-left: 2rem;
  }
  @media ${({ theme }) => theme.mediaQueries.tablet} {
    margin-bottom: 24rem;
  }
`
export const ScrollLine = styled.div`
  width: 1px;
  height: 2rem;
  background: linear-gradient(#ace31c 20%, #444847 10%);
  margin-bottom: 1rem;
`

///////////////////////////////////

export const TitleBg = styled.div`
  background: var(--color-sec);
  width: 6%;
  height: 1.2rem;
  position: relative;
  top: -3.5%;
  left: -1.5%;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    display: none;
  }

  @media ${({ theme }) => theme.mediaQueries.mobile_landscape} {
    display: none;
  }
  @media ${({ theme }) => theme.mediaQueries.tablet} {
    display: block;
    width: 10%;
    top: -2.5%;
  }
`
