import React from "react"
import Header from "../../common-components/Header/Header"
import Footer from "../../common-components/Footer/Footer"
import HomeBanner from "../../common-components/SectionComponents/HomeBanner"
import WhatWeDo from "../../common-components/SectionComponents/WhatWeDo"
import ConsultingServices from "../../common-components/SectionComponents/ConsultingServices"
import OurClients from "../../common-components/SectionComponents/OurClients"
import OurTeam from "../../common-components/SectionComponents/OurTeam"
import OurPartners from "../../common-components/SectionComponents/OurPartners"
import SendRequest from "../../common-components/SectionComponents/SendRequest"
import MenuPage from "../menu-page/MenuPage"
import MenuLink from "../../common-components/Navigation/MenuLink"
import { scroll } from "../../utils/locomotiveScroll"

function HomePage() {
  const [menuOpen, setMenuOpen] = React.useState(false)
  const [isMobile, setisMobile] = React.useState(null)
  // const [isIpadpro, setisIpadro] = React.useState(null)

  const changeMobile = () => {
    window.matchMedia("(max-width: 37.5em)").matches
      ? setisMobile(true)
      : setisMobile(false)
  }

  const changeIpadpro = () => {
    // window.matchMedia("(max-width: 37.5em)").matches
    //   ? setisIpadro(true)
    //   : setisIpadro(false)
  }

  React.useEffect(() => {
    window.scroll(0, 0)
    scroll()
    changeMobile()
    changeIpadpro()
    window.addEventListener("resize", changeMobile)
    return () => window.removeEventListener("resize", changeMobile)
  }, [])

  return (
    <>
      {isMobile ? (
        <>
          <Header />
          {!menuOpen && (
            <>
              <HomeBanner />
              <WhatWeDo />
              <ConsultingServices />
              <OurClients />
              <OurTeam />
              <OurPartners />
              <SendRequest />
              <Footer setMenuOpen={setMenuOpen} menuOpen={menuOpen}  />
            </>
          )}
          <MenuPage setMenuOpen={setMenuOpen} menuOpen={menuOpen} />
          <MenuLink setMenuOpen={setMenuOpen} menuOpen={menuOpen} />
        </>
      ) : (
        <>
          <Header />
          <HomeBanner />
          <WhatWeDo />
          <ConsultingServices />
          <OurClients />
          <OurTeam />
          <OurPartners />
          <SendRequest />
          <Footer setMenuOpen={setMenuOpen} menuOpen={menuOpen} />
          <MenuPage setMenuOpen={setMenuOpen} menuOpen={menuOpen} />
          <MenuLink setMenuOpen={setMenuOpen} menuOpen={menuOpen} />
        </>
      )}
    </>
  )
}

export default HomePage
