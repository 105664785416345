import React from "react"
import styled from "styled-components"
import {
  DetailedSayingWrapper,
  InsideSectionWrapper,
  SectionWrapper,
} from "../../building-blocks/atoms/containers/containers"
import {
  ActionLinks,
} from "../../building-blocks/atoms/texts/texts"
import { scroll } from "../../utils/locomotiveScroll"

function TermsConditionsContext() {
  React.useEffect(() => {
    scroll()
  }, [])

  return (
    <>
      <SectionWrapper style={{ height: "auto" }} data-scroll-section>
        <InsideSectionWrapper
          style={{
            padding: "12%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <>
            <BannerOverlayText
              style={{
                // width: "40%",
                // textAlign: "center",
                // marginTop: "8rem",
                // fontWeight: "300",
                // fontFamily: "graphik",
                // fontSize: "4rem",
                // letterSpacing: "0.6rem",
              }}
              data-scroll
              data-scroll-speed="-1"
            >
              Terms and Conditions
            </BannerOverlayText>
          </>
          <DetailedSayingWrapper style={{  }}>
            <ActionLinks
              style={{
                textTransform: "none",
                lineHeight: "2",
                fontWeight: "300",
                letterSpacing: "0.08rem",
                fontSize: "1.4rem",
                wordBreak: "normal",
              }}
            >
              These terms and conditions constitute a binding legal agreement
              between you and Honeysys IT Services Pvt Ltd. ("Honeysys")
              governing your access to and use of the website and its Content
              located at honeysys Please read these terms and conditions
              carefully. By Accessing, Browsing, Reviewing and/or Using the
              site, you acknowledge and agree that you have read, Understood and
              agree to be bound by these terms and conditions without limitation
              or qualification and that you shall comply with all applicable
              laws, rules and regulations. If you do not agree to our terms and
              conditions, please do not use the site.
              <br />
              <br />
              <p
                style={{
                  textTransform: "uppercase",
                  letterSpacing: "0.4rem",
                  fontSize: "1rem",
                  fontWeight: "500",
                }}
              >
                Terms of use
              </p>
              <br />
              The Honeysys site may contain additional proprietary notices and
              copyright information, the terms and conditions of which must be
              observed and followed. Honeysys may, in its sole discretion,
              revise, amend, modify or delete portions of these Terms and
              Conditions at any time without notice to you. It is your
              responsibility to read the latest version of our terms and
              conditions. Your continued use of the Site constitutes your
              acceptance of any revisions, amendments, modifications or
              deletions to these Terms and Conditions.
              <br />
              <br />
              <p
                style={{
                  textTransform: "uppercase",
                  letterSpacing: "0.4rem",
                  fontSize: "1rem",
                  fontWeight: "500",
                }}
              >
                Restrictions
              </p>
              <br />
              Subject to your ongoing compliance with these Terms and
              Conditions, Honeysys hereby grants you a limited, personal, non-
              exclusive, non-transferable, non-sublicensable, freely revocable
              license to access and use the Site solely for your personal, non-
              commercial and lawful purposes provided that you maintain all
              copyright, trademark and other intellectual property notices
              therein. Further, Honeysys does not grant you permission, by
              implication, estoppel or otherwise, to state or suggest that
              Honeysys promotes or endorses any third party’s political views,
              ideas, causes, products or services. All other rights are hereby
              expressly reserved.
            </ActionLinks>
          </DetailedSayingWrapper>
        </InsideSectionWrapper>
      </SectionWrapper>
    </>
  )
}

export default TermsConditionsContext


const BannerOverlayText = styled.h1`
  color: white;
  font-family: graphik;
  font-size: 4rem;
  letter-spacing: 0.6rem;
  font-weight: 300;
  margin-top: 8rem;
  width: 40%;
  text-align: center;
  @media ${({ theme }) => theme.mediaQueries.tablet}{
    width: 70%;
    padding: 20% 0%;

  }
  @media ${({ theme }) => theme.mediaQueries.tablet_landscape}{
    width: 70%;
    padding: 20% 0%;
    letter-spacing: 0.9rem;

  }
  @media ${({ theme }) => theme.mediaQueries.ipad_pro}{
    width: 70%;
    padding: 20% 0%;
    letter-spacing: 0.9rem;
    font-size: 5rem;
  }

  `