import React from "react"
import styled from "styled-components"
import {
  SectionWrapper,
  SayWrapper,
  DetailedSayingWrapper,
  InsideSectionWrapper,
  TitleBg,
} from "../../building-blocks/atoms/containers/OurPartnersContainer"
import {
  ActionLinks,
  BannerOverlayText,
} from "../../building-blocks/atoms/texts/OurPartnersText"
import p1 from "../../assets/images/partners/1.png"
import p2 from "../../assets/images/partners/2.png"
import p3 from "../../assets/images/partners/3.png"
import p4 from "../../assets/images/partners/4.png"
import p5 from "../../assets/images/partners/5.png"
import p6 from "../../assets/images/partners/6.png"
import p7 from "../../assets/images/partners/7.png"
import p8 from "../../assets/images/partners/8.png"
import p9 from "../../assets/images/partners/9.png"

const LIST = [
  { img: p1, link: "http://thebrewkitchen.com/" },
  { img: p2, link: "" },
  { img: p3, link: "https://cosmossports.in/" },
  { img: p4, link: "http://www.deccanrice.com/" },
  { img: p5, link: "https://emarat.ae/" },
  { img: p6, link: "" },
  { img: p7, link: "https://www.kiranaking.com/" },
  { img: p8, link: "https://www.smilykiddos.in/" },
  {
    img: p9,
    link: "https://www.goshoppi.ae/store/university-bookshop-al-khan/",
  },
]

const ImgLink = styled.img`
  width: 8rem;
  padding: 1.5rem;
  height: auto;
  object-fit: cover;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    width: 6rem;
    padding: 0.7rem;
    height: auto;
    object-fit: cover;
  }

  @media ${({ theme }) => theme.mediaQueries.mobile_landscape} {
    width: 6rem;
    padding: 0.7rem;
    height: auto;
    object-fit: cover;
  }
  @media ${({ theme }) => theme.mediaQueries.tablet}{
    width: 8.5rem;
  }
  

`

const OurPartners = (props) => {
  // const [isMobile, setisMobile] = React.useState(null)

  const changeMobile = () => {
    // window.matchMedia("(max-width: 37.5em)").matches
    //   ? setisMobile(true)
    //   : setisMobile(false)
  }

  React.useEffect(() => {
    changeMobile()
    window.addEventListener("resize", changeMobile)
    return () => window.removeEventListener("resize", changeMobile)
  }, [])

  // console.log(props)
  return (
    <SectionWrapper data-scroll-section>
      <InsideSectionWrapper>
        <SayWrapper>
          <ActionLinks>Our partners</ActionLinks>

          <TitleBg />
        </SayWrapper>
        <>
          <BannerOverlayText data-scroll data-scroll-speed="1">
            A Trusted Partner.
          </BannerOverlayText>
        </>
        <DetailedSayingWrapper
          data-scroll
          data-scroll-speed="2"
          style={{
            margin: "0%",
          }}
        >
          {LIST.map((i) => (
            <a href={i.link} target="_blank" rel="noreferrer">
              <ImgLink alt="" src={i.img} />
            </a>
          ))}
        </DetailedSayingWrapper>
      </InsideSectionWrapper>
    </SectionWrapper>
  )
}

export default OurPartners
