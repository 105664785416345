import styled from "styled-components"

export const BannerOverlayText = styled.h1`
  color: var(--color-blk);
  font-family: graphik;
  margin-top: 4rem;

  width: 35%;
  font-weight: 600;
  font-size: 2rem;
  letter-spacing: 0rem;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    font-size: 1.5rem;
    font-weight: 800;
    width: 100%;
    line-height: 2.5rem;
    margin-top: 3rem;
    margin-bottom: 5rem;
  }

  @media ${({ theme }) => theme.mediaQueries.mobile_landscape} {
    font-size: 1.5rem;
    font-weight: 800;
    width: 100%;
    line-height: 2.5rem;
    margin-top: 2rem;
    margin-bottom: 3rem;
  }
  @media ${({ theme }) => theme.mediaQueries.ipad_pro}{
    width: 48%;
  }
  @media ${({ theme }) => theme.mediaQueries.tablet}{
    width: 55%;
  }
  @media ${({ theme }) => theme.mediaQueries.tablet_landscape}{
    width: 65%;
  }

`

export const ActionLinks = styled.p`
  color: white;
  font-size: 0.9rem;
  font-family: "Roboto";
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0.2rem;
  z-index: 1;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    font-size: 0.7rem;
    opacity: 0.8;
    letter-spacing: 0.1rem;
    font-weight: 400;
  }

  @media ${({ theme }) => theme.mediaQueries.mobile_landscape} {
    font-size: 0.7rem;
    opacity: 0.8;
    -webkit-letter-spacing: 0.28rem;
    -moz-letter-spacing: 0.28rem;
    -ms-letter-spacing: 0.28rem;
    -webkit-letter-spacing: 0.28rem;
    -moz-letter-spacing: 0.28rem;
    -ms-letter-spacing: 0.28rem;
    -webkit-letter-spacing: 0.28rem;
    -moz-letter-spacing: 0.28rem;
    -ms-letter-spacing: 0.28rem;
    letter-spacing: 0.28rem;
    font-weight: 400;

    font-weight: 600;
    letter-spacing: 0.1rem;
  }
`

export const SubActionLinks = styled.p`
  color: var(--color-blk);
  font-size: 0.8rem;
  font-family: "Roboto";
  color: var(--color-blk);
  z-index: 1;

  text-transform: none;
  line-height: 2;
  font-weight: 400;
  width: 45%;
  letter-spacing: 0.08rem;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    text-transform: none;
    line-height: 2;
    font-weight: 400;
    width: 92%;
    letter-spacing: 0.08rem;
  }

  @media ${({ theme }) => theme.mediaQueries.mobile_landscape} {
    text-transform: none;
    line-height: 2;
    font-weight: 300;
    width: 92%;
    letter-spacing: 0.08rem;
    font-size: 0.75rem;
  }

  @media ${({ theme }) => theme.mediaQueries.tablet}{
    width: 60%;
    margin-left: 30%;
  }
`
