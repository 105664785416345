// import { Link } from "react-router-dom"
import styled from "styled-components"

export const SectionWrapper1 = styled.div`
  color: white;
  display: flex;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
  background-color: var(--color-comp);
  height: 90vh;
  z-index: 2;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    height: 75vh;
  }
  @media ${({ theme }) => theme.mediaQueries.ipad_pro} {
    height: 75vh;
  }
`

export const SectionWrapper2 = styled.div`
  color: white;
  display: flex;
  width: 100%;
  height: 120vh;
  align-items: center;
  justify-content: center;
  background-color: var(--color-comp);

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    height: 100vh;
  }

  @media ${({ theme }) => theme.mediaQueries.mobile_landscape} {
    height: 100vh;
  }
`

export const SectionWrapper3 = styled.div`
  color: white;
  display: flex;
  width: 100%;
  height: 85vh;
  align-items: center;
  justify-content: center;
  background-color: var(--color-comp);

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    height: 90vh;
  }

  @media ${({ theme }) => theme.mediaQueries.mobile_landscape} {
    height: 100vh;
  }
  @media ${({ theme }) => theme.mediaQueries.tablet} {
    height: 60vh;
  }
  @media ${({ theme }) => theme.mediaQueries.ipad_pro} {
    height: 40vh;
  }
`

export const SectionWrapper5 = styled.div`
  color: white;
  display: flex;
  width: 100%;
  height: 60vh;
  align-items: center;
  justify-content: center;
  background-color: var(--color-comp);

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    height: 55vh;
  }

  @media ${({ theme }) => theme.mediaQueries.mobile_landscape} {
    height: 70vh;
  }
  @media ${({ theme }) => theme.mediaQueries.tablet} {
    height: 40vh;
  }
  @media ${({ theme }) => theme.mediaQueries.ipad_pro} {
    height: 40vh;
  }
`

export const SectionWrapper4 = styled.div`
  color: white;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  background-color: var(--color-comp);
  height: 80vh;
  justify-content: flex-start;
  z-index: 2;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    height: 120vh;
    flex-direction: column;
    padding: 0%;
    margin: 0%;
  }

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    height: 100vh;
  }
  @media ${({ theme }) => theme.mediaQueries.tablet} {
    height: 70vh;
  }
`

export const SectionWrapper = styled.div`
  color: white;
  display: flex;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
  background-color: var(--color-comp);
`

export const SectionWrapper6 = styled.div`
  color: white;
  display: flex;
  width: 100%;
  height: 120vh;
  align-items: center;
  justify-content: center;
  background-color: var(--color-comp);
  z-index: 2;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  @media ${({ theme }) => theme.mediaQueries.tablet_landscape} {
    display: flex;
    flex-direction: row;
  }
  @media ${({ theme }) => theme.mediaQueries.tablet} {
    display: flex;
    flex-direction: row;
  }

  @media ${({ theme }) => theme.mediaQueries.ipad_pro}{
    display: flex;
    flex-direction: row;
    height: 70vh;
  }
`

export const InsideSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: flex-start;
  padding: 6% 18%;
`

export const InsideSectionWrapper3 = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 12%;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    height: 80%;
    padding: 12% 12% 0% 12%;
  }

  @media ${({ theme }) => theme.mediaQueries.mobile_landscape} {
    padding: 6% 18%;
  }
`

export const InsideSectionWrapper2 = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  padding: 6% 18%;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    padding: 20% 12% 0% 12%;
    width: 70%;
  }

  @media ${({ theme }) => theme.mediaQueries.mobile_landscape} {
    padding: 6%;
  }
  @media ${({ theme }) => theme.mediaQueries.tablet}{
    padding: 0% 12% 0% 12%;
  }
`

export const InsideSectionWrapper1 = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 15% 18% 0% 15%;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    align-items: flex-start;
    justify-content: flex-start;
    padding: 60% 12% 30% 12%;
  }
  @media ${({ theme }) => theme.mediaQueries.tablet} {
    align-items: flex-start;
    justify-content: flex-start;
    padding: 35% 0% 0% 15%;
  }
  @media ${({ theme }) => theme.mediaQueries.ipad_pro}{
    padding: 25% 0% 0% 15%;
  }
`

export const PrevNextWrapper = styled.div`
  display: flex;
  width: auto;
  align-items: center;
  justify-content: center;
`

export const SubActWrapper = styled.div`
  display: flex;
  width: auto;
  align-items: center;
  justify-content: center;

  @media ${({ theme }) => theme.mediaQueries.mobile_landscape} {
    display: none;
  }
`

export const SubActWrapper1 = styled.div`
  display: flex;
  width: auto;
  align-items: center;
  justify-content: center;
  margin-left: -17rem;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    width: 100%;
    justify-content: flex-start;
    margin: 0%;
    margin-top: 6vh;
  }

  @media ${({ theme }) => theme.mediaQueries.mobile_landscape} {
    display: none;
  }
`

export const DetailedSayingWrapper = styled.div`
  display: flex;
  width: 100%;
  height: auto;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 2rem;
`

export const DetailedSayingWrapper2 = styled.div`
  display: flex;
  width: 100%;
  height: auto;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 5rem;
  margin-bottom: 3rem;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    margin-top: 0rem;
    margin-bottom: 3rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  @media ${({ theme }) => theme.mediaQueries.mobile_landscape} {
    margin: 0%;
  }
`

export const DetailedSayingWrapper1 = styled.div`
  display: flex;
  width: 100%;
  height: auto;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 3rem;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    justify-content: flex-start;
    margin: 0%;
  }

  @media ${({ theme }) => theme.mediaQueries.mobile_landscape} {
    width: 74%;
  }
`

export const ServicesWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: inherit;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 6% 12%;
`

export const ServicesWrapper3 = styled.div`
  position: absolute;
  width: 100%;
  height: inherit;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 28% 20% 0% 55%;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    padding: 0% 12%;
    position: unset;
    justify-content: flex-start;
    align-items: flex-start;
  }

  @media ${({ theme }) => theme.mediaQueries.mobile_landscape} {
    display: none;
  }
  @media ${({ theme }) => theme.mediaQueries.tablet}{
    padding: 45% 5%;
  }
  @media ${({ theme }) => theme.mediaQueries.tablet_landscape}{
    margin-left: 8rem;
  }
  @media ${({ theme }) => theme.mediaQueries.ipad_pro}{
    // padding: 45% 5%;
  }
`

export const ServicesWrapper1 = styled.div`
  position: absolute;
  width: 100%;
  height: inherit;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 6% 12%;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    width: 100vw;
    padding: 0%;
    height: 60vh;
    margin: 0%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: unset;
  }
`

export const ServicesWrapper2 = styled.div`
  position: absolute;
  width: 100%;
  height: inherit;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 6% 0% 0% 55%;
  background-color: transparent;
  justify-content: flex-end;
  z-index: 2;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    height: 60vh;
    padding: 0%;
    margin: 0%;
    background-color: transparent;
    justify-content: flex-end;
    padding: 10% 12%;
    background-color: transparent;
    justify-content: center;
    align-items: center;
    z-index: 2;
    position: unset;
  }

  @media ${({ theme }) => theme.mediaQueries.mobile_landscape} {
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0% 6% 6% 62%;
  }

  @media ${({ theme }) => theme.mediaQueries.tablet} {
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0% 0% 0% 60%;
  }

  @media ${({ theme }) => theme.mediaQueries.ipad_pro}{
    padding: 0% 0% 30% 60%;
  }
`
export const TitleBg = styled.div`
  background: var(--color-sec);
  width: 12%;
  height: 1rem;
  position: relative;
  top: -4%;
  left: 2%;

  @media ${({ theme }) => theme.mediaQueries.mobile} {
    display: none;
  }

  @media ${({ theme }) => theme.mediaQueries.mobile_landscape} {
    display: none;
  }
  @media ${({ theme }) => theme.mediaQueries.ipad_pro} {
    top: -3%;
  }
`
